import React, { useState, useEffect, useRef, useReducer } from 'react'
import { Edit, Trash2, ArrowLeftCircle, Download } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import { Typeahead } from 'react-bootstrap-typeahead'
import * as yup from 'yup'
import SweetAlert from 'sweetalert2'
import { Modal, Pagination, Spinner } from 'react-bootstrap'
import { calMaxPage } from '../../../common/Functions/CommonFunctions'
import EditParameter from './EditParameter'
import { save_parameter, get_parameters, delete_parameter } from '../../../api'

const schema = yup
  .object()
  .shape({
    sequenceno: yup.string().required('Sequence number is required!').matches(/^\d+$/, 'Sequence number must be a number.'),
    name: yup.string().required('Parameter name is required !'),
    unit: yup.string().required('Unit is required'),
    type: yup.array().min(1, 'Select one type').required('Type is required !'),
  })
  .required()

function Parameter() {
  let navigate = useNavigate()
  const [ves, setVes] = useState([])
  const [visible, setVisible] = useState(false)

  const [apiBySearch, setApiBySearch] = useState(false)
  const [dataForUpdation, setDataForUpdation] = useState()
  const [srNo, setSrNo] = useState(0)
  const [totalRecords, setTotalRecords] = useState('')
  const [loading, setLoading] = useState(false)

  const userInfo = JSON.parse(localStorage.getItem('userInfo'))

  const { register, handleSubmit, control, formState, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  let { errors } = formState

  const prmList = [
    { id: 1, name: 'weather', label: 'Weather' },
    { id: 2, name: 'calculation', lable: 'Calculation' },
  ]

  useEffect(() => {
    if (visible) {
      reset()
    }
  }, [visible])

  const initialState = {
    loading: true,
    error: '',
    parameterList: [],
    limit: 10,
    offset: 1,
    maxPage: 0,
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case 'HANDLE_ACTION':
        return {
          offset: action.offset,
          limit: action.limit,
          loading: action.loading,
          error: action.error,
          parameterList: action.parameterList,
          maxPage: action.maxPage,
        }

      default:
        return state
    }
  }

  const [state, dispatch] = useReducer(reducer, initialState)

  useEffect(() => {
    console.log('userInfo', userInfo)
    console.log('state', state)
    dispatch({
      type: 'HANDLE_ACTION',
      offset: state.offset,
      loading: true,
      error: state.error,
      parameterList: state.parameterList,
      limit: state.limit,
      maxPage: state.maxPage,
    })

    fetchParameters()
  }, [state.offset, state.limit])

  function fetchParameters(para1, para2) {
    dispatch({
      type: 'HANDLE_ACTION',
      offset: para1 === 'refresh' ? 1 : state.offset,
      loading: true,
      error: state.error,
      parameterList: state.parameterList,
      limit: state.limit,
      maxPage: state.maxPage,
    })

    var reqPayload = {
      offset: state.offset - 1,
      limit: state.limit,
    }

    if (para1 === 'search') {
      setSrNo(0);
      reqPayload = {
        // offset: 1,
        // limit: 10,
        search: para2,
      }
    }

    get_parameters(reqPayload).then(
      (res) => {
        console.log('res', res.data.data.response.results)
        console.log(
          'calMaxPage(res.data.data.response.count.totalData)',
           calMaxPage(res.data.data.response.count.totalData),
        )
        setTotalRecords(res.data.data.response.count.totalData)
        
          dispatch({
            type: 'HANDLE_ACTION',
            offset: state.offset,
            loading: false,
            error: state.error,
            parameterList: res.data.data.response.results,
            limit: state.limit,
            maxPage:
              para1 === 'search'
                ? calMaxPage(res.data.data.response.count.totalData)
                : calMaxPage(res.data.data.response.count.totalData),
            // Checking if user is searching customer, using above logic for pagination
          })
      },
      (err) => {
        console.log('err', err)

        if (err.response.status === 500) {
          toast.error('Something went wrong !', { autoClose: 2000 })
        }
      },
    )
  }

  const onSubmit = (data) => {
    console.log('data', data)
    if (data !== '') {
      setLoading(true)
      const payload = {
        customerid: userInfo.customerid,
         // vessel_admin: userInfo.user_id,
        sequenceno: data.sequenceno,
        name: data.name,
        unit: data.unit,
        type: data.type[0]?.name,
      }

      console.log('payload', payload)

      save_parameter(payload).then(
        (res) => {
          console.log('res', res)
          if (res.status === 200 || res.status === 201) {
            toast.success('Parameter record created Successfully !', {
              autoClose: 2000,
            })
            fetchParameters()
            setLoading(false)
            setVisible(false)
            reset()
          }
        },
        (err) => {
          console.log('err.response.status', err.response.status)
          if (err.response.status === 400) {
            console.log('err.response', err.response)
            toast.error(err.response.data.data.response, {
              autoClose: 2000,
            })
          }
          setTimeout(() => {
            setVisible(false)
            setLoading(false)
            reset()
          }, 2000);
        },
      )
    } else {
      toast.error('Something went wrong, please try again !', {
        autoClose: 2000,
      })
    }
  }

  // Deleting Parameter
  const delParameter = (val) => {
    console.log('val', val)
    SweetAlert.fire({
      title: 'Are you sure? \n' + val.name,
      text: 'Once deleted, you will not be able to recover this record !',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ok',
      cancelButtonText: 'Cancel',
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        delete_parameter({ id: val.id }).then(
          (res) => {
            console.log('res', res)
            toast.success('Parameter record deleted successfully !', {
              autoClose: 2000,
            })
            fetchParameters()
          },
          (err) => {
            console.log('err', err)
            toast.error('Something went wrong !', { autoClose: 5000 })
          },
        )
      }
    })
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <Modal show={visible} onHide={() => setVisible(false)} size={'lg'}>
        <Modal.Header className="bg-primary-blue" closeButton>
          <Modal.Title>{'Add Parameter'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Sequence No"}</label>
                <input
                  className={
                    errors.sequenceno
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter sequence no"
                  name="sequenceno"
                  {...register("sequenceno")}
                />

                <span className="text-danger">
                  {errors.sequenceno?.message}
                </span>
              </div>
            </div>
            
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Parameter Name"}</label>
                <input
                  className={
                    errors.name
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter parameter name"
                  name="name"
                  {...register("name")}
                />

                <span className="text-danger">
                  {errors.name?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Unit"}</label>
                <input
                  className={
                    errors.unit
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter unit"
                  name="unit"
                  {...register("unit")}
                />

                <span className="text-danger">
                  {errors.unit?.message}
                </span>
              </div>
            </div>
            
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Type"}</label>
                <Controller
                name="type"
                control={control}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Typeahead
                    id="basic-typeahead-single"
                    labelKey="name"
                    multiple={false}
                    // options={cnt !== undefined || cnt !== '' ? cnt : []}
                    options={prmList !== undefined ? prmList : []}
                    placeholder="Select Type"
                    onChange={onChange}
                    // onBlur={onBlur}
                    // selected={value}
                  />
                )}
              />
                <span className="text-danger">
                  {errors.type?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setVisible(false)}>
            Close
          </button>
          <button className="btn btn-primary-blue" onClick={handleSubmit(onSubmit)} disabled={loading}>
            {loading && <Spinner animation='border' variant="dark" size="sm" />}

            {' Save'}
          </button>
        </Modal.Footer>
      </Modal>

      <div className="py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card_title bg-primary-blue">
                  <h5 className="m-0">
                    <ArrowLeftCircle className="me-2 cursor-pointer" onClick={() => navigate(-1)} />
                    {' Parameter'}
                  </h5>
                </div>

                <div className="card-body">
                  {/* <CreateVessalMaster /> */}
                  <div className="d-flex justify-content-between col-md-12">
                    <input
                      style={{ maxWidth: '300px' }}
                      className="form-control"
                      type="text"
                      name="search"
                      placeholder="Search"
                      onChange={(e) => {
                        if (e.target.value.length > 0) {
                          fetchParameters('search', e.target.value);
                          setApiBySearch(true);
                        } else {
                          fetchParameters('refresh');
                          setApiBySearch(false);
                        }
                      }}
                      
                    />
                    <button
                      className="btn bg-primary-blue "
                      type="submit"
                      onClick={() => setVisible(!visible)}
                    >
                      {'Add Parameter'}
                    </button>
                  </div>

                  <div className="table-responsive" style={{ minHeight: '40vh' }}>
                    <table
                      className="table table-bordered mt-4 table-striped table-sm"
                      width={'100%'}
                    >
                      <thead className="thead-light">
                        <tr className="f-16 text-center">
                          <th scope="col">{'#'}</th>
                          <th scope="col">{'Name'}</th>
                          <th scope="col">{'Unit'}</th>
                          <th scope="col">{'Type'}</th>
                          <th scope="col">{'Action'}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.loading ? (
                          <div className="col-xs-4 col-xs-offset-4">
                            <Spinner color="primary" size="sm" />
                          </div>
                        ) : (
                          state.parameterList.map((val, i) => (
                            <tr key={i} className=" text-center f-16">
                              <td>{i + srNo + 1}</td>
                              <td>{val.name}</td>
                              <td>{val.unit}</td>
                              <td>{val.type}</td>
                              <td>
                                <EditParameter fetchParameters={fetchParameters} data={val} />
                                <Trash2
                                  height="18"
                                  width="18"
                                  className="cursor-pointer me-1"
                                  onClick={() => delParameter(val)}
                                />
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* ) : apiBySearch ? (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {'No Vessels Found !'}
                    </p>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {'Vessel list is empty'}
                    </p>
                  )} */}
                
                  <div className="card-body p-0">
                    <div className="mt-4">
                    {!apiBySearch ? 
                      <Pagination
                        className="justify-content-end mb-0"
                        aria-label="Page navigation example"
                      >
                        <Pagination.Item
                          disabled={state.offset === 1 ? true : false}
                          className="cursor_pointer"
                          onClick={() => {
                            dispatch({
                              type: 'HANDLE_ACTION',
                              offset: state.offset - 1,
                              loading: state.loading,
                              error: state.error,
                              parameterList: state.parameterList,
                              limit: state.limit,
                              maxPage: state.maxPage,
                            })
                            setSrNo((prevC) => prevC - 10)
                          }}
                        >
                          {'Previous'}
                        </Pagination.Item>
                        <Pagination.Item active>{state.offset}</Pagination.Item>
                        <Pagination.Item
                          disabled={
                            state.offset === state.maxPage || state.maxPage === 0 ? true : false
                          }
                          className="cursor_pointer"
                          onClick={() => {
                            dispatch({
                              type: 'HANDLE_ACTION',
                              offset: state.offset + 1,
                              loading: state.loading,
                              error: state.error,
                              parameterList: state.parameterList,
                              limit: state.limit,
                              maxPage: state.maxPage,
                            })
                            setSrNo((prevC) => prevC + 10)
                          }}
                        >
                          {'Next'}
                        </Pagination.Item>
                      </Pagination>
                    : null }
                      <p className="f-14 text-muted float-start mt-1">{`Page: ${state.offset} out of ${state.maxPage}`}</p>
                      <p className="f-14 text-muted float-end mt-1">{`Total Records: ${totalRecords}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default Parameter
