export const RefreshToken = "/api/token/refresh";

export const optiHeatWebSignIn = "login-user";

// Vessel Master
export const getVessels = "get-vessels";
export const saveVessel = "add-vessel";
export const updateVessel = "update-vessel?";
export const removeVessel = "delete-vessel?";


// Tank Master
export const getTanks = "get-tanks";
export const saveTank = "add-tank";
export const updateTank = "update-tank?";
export const removeTank = "delete-tank?";


// Boiler Master
export const getBoilers = "get-boilers";
export const saveBoiler = "add-boiler";
export const updateBoiler = "update-boiler?";
export const removeBoiler = "delete-boiler?";

// Boiler Specifications
export const saveBoilerSpec = "add-boiler-specification"


// User Master
export const getUsers = "get-users";
export const saveUser = "add-user";
export const updateUser = "update-user?";
export const removeUser = "delete-user?";

// vessel-admin mster
export const getVesseladmin = "get-vessel-admins";
export const saveVesseladmin = "add-vessel-admin";
export const updateVesseladmin = "update-vessel-admin";
export const deleteVesseladmin = "delete-vessel-admin";
export const forgetPassword = 'forgot-password'
export const resetPassword = 'reset-password'

// Parameter Master
export const getParameters = "get-parameters";
export const saveParameter = "add-parameter";
export const updateParameter = "update-parameter?";
export const removeParameter = "delete-parameter?";

// Parameter Master
export const getParameterConstants = "get-constants";
export const saveParameterConstant = "add-constant";
export const updateParameterConstant = "update-constant?";
export const removeParameterConstant = "delete-constant?";
