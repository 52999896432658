import React, { useState } from "react";
import SideNav, {
  Toggle,
  Nav,
  NavItem,
  NavIcon,
  NavText,
} from "@trendmicro/react-sidenav";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCartShopping,
  faFileAlt,
  faShip,
  faCloudUpload,
  faTruck,
  faUser,
  faBars,
  faParagraph,
  faBarChart,
} from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useLocation, Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/project/OPTI HEAT.png";

function Sidebar() {
  const [toggle, setToggle] = useState(false);
  let navigate = useNavigate();
  let url = useLocation();
  console.log("url", url);
  // let toggle = false;

  const [isExpanded, setIsExpanded] = useState(false);

  const handleToggle = () => {
    setIsExpanded(!isExpanded);
  };

  function reSize() {
    var screenWidth = window.innerWidth;

    if (screenWidth > 768) {
      // Add margin to the div for small screens
      var div1 = document.getElementById("root_body");
      var div2 = document.getElementById("navbar");
      if (toggle == true) {
        div1.style.marginLeft = "64px";
        div2.style.marginLeft = "64px";
      } else {
        div1.style.marginLeft = "240px";
        div2.style.marginLeft = "240px";
      }
    } else {
      // No margin needed for larger screens
    }
  }

  return (
    <SideNav
      onSelect={(selected) => {
        console.log(selected);
        navigate(selected);
      }}
      onToggle={() => {
        reSize();
        // toggle = !toggle;
        setToggle(!toggle);
      }}
    >
      <SideNav.Toggle />
      <div className={toggle ? "" : "text-center"}>
        <img
          src={logo}
          id="logo"
          className="p-1"
          alt="logo"
          height={toggle ? 100 : 50}
        />
      </div>
      <SideNav.Nav defaultSelected={url.pathname}>
        <NavItem>
          <NavIcon>
            <FontAwesomeIcon
              style={{ color: "#e9e9e9" }}
              icon={faBars}
              size="lg"
            />
          </NavIcon>
          <NavText>Masters</NavText>
          {/* {isExpanded && (
              <Nav> 
                <NavItem className="ms-3" eventKey="/optiheat/vesselMaster">
                  <NavIcon>
                    <FontAwesomeIcon style={{ color: '#e9e9e9' }} icon={faShip} size="lg" />
                  </NavIcon>
                  <NavText>Vessel</NavText>
                </NavItem>
                <NavItem className="ms-3" eventKey="/optiheat/parameterMaster">
                <NavIcon>
                    <FontAwesomeIcon style={{ color: '#e9e9e9' }} icon={faParagraph} size="lg" />
                  </NavIcon>
                  <NavText>Parameter</NavText>
                </NavItem>
                <NavItem className="ms-3" eventKey="/optiheat/constantMaster">
                <NavIcon>
                    <FontAwesomeIcon style={{ color: '#e9e9e9' }} icon={faBarChart} size="lg" />
                  </NavIcon>
                  <NavText>Constants</NavText>
                </NavItem>
              </Nav>
          )} */}
        </NavItem>
        <NavItem className="ms-3" eventKey="/optiheat/vesselMaster">
          <NavIcon>
            <FontAwesomeIcon
              style={{ color: "#e9e9e9" }}
              icon={faShip}
              size="lg"
            />
          </NavIcon>
          <NavText>Vessel</NavText>
        </NavItem>
        <NavItem className="ms-3" eventKey="/optiheat/parameterMaster">
          <NavIcon>
            <FontAwesomeIcon
              style={{ color: "#e9e9e9" }}
              icon={faParagraph}
              size="lg"
            />
          </NavIcon>
          <NavText>Parameter</NavText>
        </NavItem>
        <NavItem className="ms-3" eventKey="/optiheat/constantMaster">
          <NavIcon>
            <FontAwesomeIcon
              style={{ color: "#e9e9e9" }}
              icon={faBarChart}
              size="lg"
            />
          </NavIcon>
          <NavText>Constants</NavText>
        </NavItem>
        <NavItem eventKey="/optiheat/overallAnalysis">
          <NavIcon>
            <FontAwesomeIcon
              style={{ color: "#e9e9e9" }}
              icon={faFileAlt}
              size="lg"
            />
          </NavIcon>
          <NavText>Report</NavText>
        </NavItem>
        <NavItem eventKey="/optiheat/userMaster">
          <NavIcon>
            <FontAwesomeIcon
              style={{ color: "#e9e9e9" }}
              icon={faUser}
              size="lg"
            />
          </NavIcon>
          <NavText>Vessel Admin</NavText>
        </NavItem>
      </SideNav.Nav>
    </SideNav>
  );
}

export default Sidebar;
