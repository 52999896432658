import React, { useState, useEffect, useRef, useReducer } from "react";
import { Edit, Trash2, ArrowLeftCircle, Download } from "react-feather";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import { Typeahead } from "react-bootstrap-typeahead";
import * as yup from "yup";
import SweetAlert from "sweetalert2";
import { Modal, Pagination, Spinner } from "react-bootstrap";
import { calMaxPage } from "../../../common/Functions/CommonFunctions";
import EditParameterConstants from "./EditParameterConstants";
import {
  save_ParameteConstant,
  get_ParameterConstants,
  delete_ParameteConstant,
} from "../../../api";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Constant name is required !"),
    identifier: yup
      .string()
      .required("Identifier name is required !")
      .matches(/^[A-Z]+$/, "Only capital letters are allowed"),
    unit: yup.string().required("Unit is required"),
    constValue: yup
      .string()
      .required("Value is required !")
      .matches(/^\d+(\.\d{1,2})?$/, "Invalid value"),
  })
  .required();

function ParameterConstants() {
  let navigate = useNavigate();
  const [ves, setVes] = useState([]);
  const [visible, setVisible] = useState(false);

  const [apiBySearch, setApiBySearch] = useState(false);
  const [dataForUpdation, setDataForUpdation] = useState();
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const [loading, setLoading] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { register, handleSubmit, control, formState, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  let { errors } = formState;

  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [visible]);

  const initialState = {
    loading: true,
    error: "",
    constantList: [],
    limit: 10,
    offset: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "HANDLE_ACTION":
        return {
          offset: action.offset,
          limit: action.limit,
          loading: action.loading,
          error: action.error,
          constantList: action.constantList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log("userInfo", userInfo);
    console.log("state", state);
    dispatch({
      type: "HANDLE_ACTION",
      offset: state.offset,
      loading: true,
      error: state.error,
      constantList: state.constantList,
      limit: state.limit,
      maxPage: state.maxPage,
    });

    fetchConstants();
  }, [state.offset, state.limit]);

  function fetchConstants(para1, para2) {
    dispatch({
      type: "HANDLE_ACTION",
      offset: para1 === "refresh" ? 1 : state.offset,
      loading: true,
      error: state.error,
      constantList: state.constantList,
      limit: state.limit,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      offset: state.offset - 1,
      limit: state.limit,
    };

    if (para1 === "search") {
      reqPayload = {
        // offset: state.offset,
        // limit: state.limit,
        search: para2,
      };
    }

    get_ParameterConstants(reqPayload).then(
      (res) => {
        console.log("res", res.data.data.response.results);
        console.log(
          "calMaxPage(res.data.count)",
          calMaxPage(res.data.data.response.count.totalData)
        );
        setTotalRecords(res.data.data.response.count.totalData);

        dispatch({
          type: "HANDLE_ACTION",
          offset: state.offset,
          loading: false,
          error: state.error,
          constantList: res.data.data.response.results,
          limit: state.limit,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.data.response.count.totalData)
              : calMaxPage(res.data.data.response.count.totalData),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", { autoClose: 2000 });
        }
      }
    );
  }

  const onSubmit = (data) => {
    console.log("data", data);
    if (data !== "") {
      setLoading(true);
        if(data.identifier === 'BSPC' && parseFloat(data.constValue) < 100000 ){
          toast.error("Boiler steam production capacity above or equal to 100000 Kg/h !", {
            autoClose: 2000,
          });
          return
        }
      const payload = {
        customerid: userInfo.customerid,
        // vessel_admin: userInfo.user_id,
        name: data.name,
        identifier: data.identifier,
        unit: data.unit,
        value: parseFloat(data.constValue),
      };

      console.log("payload", payload);

      save_ParameteConstant(payload).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Parameter constant record created Successfully !", {
              autoClose: 2000,
            });
            fetchConstants();
            setLoading(false);
            setVisible(false);
            reset();
          }
        },
        (err) => {
          console.log("err.response.status", err.response.status);
          if (err.response.status === 400) {
            console.log("err.response", err.response);
            toast.error(err.response.data?.data?.response, {
              autoClose: 2000,
            });
            setVisible(false);
            setLoading(false);
            reset();
          }
        }
      );
    } else {
      toast.error("Something went wrong, please try again !", {
        autoClose: 1000,
      });
    }
  };

  // Deleting Parameter Constant
  const delConstant = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure? \n" + val.name,
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        delete_ParameteConstant({ id: val.id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Constant record deleted successfully !", {
              autoClose: 2000,
            });
            fetchConstants();
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", { autoClose: 5000 });
          }
        );
      }
    });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <Modal show={visible} onHide={() => setVisible(false)} size={"lg"}>
        <Modal.Header className="bg-primary-blue" closeButton>
          <Modal.Title>{"Add Constants"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">
        
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Constant Name"}</label>
                <input
                  className={
                    errors.name
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter constant name"
                  name="name"
                  {...register("name")}
                />

                <span className="text-danger">
                  {errors.name?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Identifier"}</label>
                <input
                  className={
                    errors.identifier
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter identifier"
                  name="identifier"
                  {...register("identifier")}
                />

                <span className="text-danger">
                  {errors.identifier?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Unit"}</label>
                <input
                  className={
                    errors.unit
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter unit"
                  name="unit"
                  {...register("unit")}
                />

                <span className="text-danger">
                  {errors.unit?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Value"}</label>
                <input
                  className={
                    errors.constValue
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter constant value"
                  name="constValue"
                  {...register("constValue")}
                />

                <span className="text-danger">
                  {errors.constValue?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setVisible(false)}>
            Close
          </button>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
          >
            {loading && <Spinner animation='border' variant="dark" size="sm" />}

            {" Save"}
          </button>
        </Modal.Footer>
      </Modal>

      <div className="py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card_title bg-primary-blue">
                  <h5 className="m-0">
                    <ArrowLeftCircle
                      className="me-2 cursor-pointer"
                      onClick={() => navigate(-1)}
                    />
                    {" Constants"}
                  </h5>
                </div>

                <div className="card-body">
                  {/* <CreateVessalMaster /> */}
                  <div className="d-flex justify-content-between col-md-12">
                    <input
                      style={{ maxWidth: "300px" }}
                      className="form-control"
                      type="text"
                      name="search"
                      placeholder="Search"
                      onChange={(e) => {
                        fetchConstants("search", e.target.value);
                      }}
                    />
                    <button
                      className="btn bg-primary-blue "
                      type="submit"
                      onClick={() => setVisible(!visible)}
                    >
                      {"Add Constants"}
                    </button>
                  </div>

                  <div
                    className="table-responsive"
                    style={{ minHeight: "40vh" }}
                  >
                    <table
                      className="table table-bordered mt-4 table-striped table-sm"
                      width={"100%"}
                    >
                      <thead className="thead-light">
                        <tr className="f-16 text-center">
                          <th scope="col">{"#"}</th>
                          <th scope="col">{"Name"}</th>
                          <th scope="col">{"Identifier"}</th>
                          <th scope="col">{"Unit"}</th>
                          <th scope="col">{"Value"}</th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.loading ? (
                          <Spinner animation="border" variant="dark" role="status" />
                        ) : (
                          state.constantList.map((val, i) => (
                            <tr key={i} className=" text-center f-16">
                              <td>{i + srNo + 1}</td>
                              <td>{val.name}</td>
                              <td>{val.identifier}</td>
                              <td>{val.unit}</td>
                              <td>{val.value}</td>
                              <td>
                                <EditParameterConstants
                                  fetchConstants={fetchConstants}
                                  data={val}
                                />
                                <Trash2
                                  height="18"
                                  width="18"
                                  className="cursor-pointer me-1"
                                  onClick={() => delConstant(val)}
                                />
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* ) : apiBySearch ? (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {'No Vessels Found !'}
                    </p>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {'Vessel list is empty'}
                    </p>
                  )} */}
                  <div className="card-body p-0">
                    <div className="mt-4">
                      <Pagination
                        className="justify-content-end mb-0"
                        aria-label="Page navigation example"
                      >
                        <Pagination.Item
                          disabled={state.offset === 1 ? true : false}
                          className="cursor_pointer"
                          onClick={() => {
                            dispatch({
                              type: "HANDLE_ACTION",
                              offset: state.offset - 1,
                              loading: state.loading,
                              error: state.error,
                              constantList: state.constantList,
                              limit: state.limit,
                              maxPage: state.maxPage,
                            });
                            setSrNo((prevC) => prevC - 10);
                          }}
                        >
                          {"Previous"}
                        </Pagination.Item>
                        <Pagination.Item active>{state.offset}</Pagination.Item>
                        <Pagination.Item
                          disabled={
                            state.offset === state.maxPage ||
                            state.maxPage === 0
                              ? true
                              : false
                          }
                          className="cursor_pointer"
                          onClick={() => {
                            dispatch({
                              type: "HANDLE_ACTION",
                              offset: state.offset + 1,
                              loading: state.loading,
                              error: state.error,
                              constantList: state.constantList,
                              limit: state.limit,
                              maxPage: state.maxPage,
                            });
                            setSrNo((prevC) => prevC + 10);
                          }}
                        >
                          {"Next"}
                        </Pagination.Item>
                      </Pagination>

                      <p className="f-14 text-muted float-start mt-1">{`Page: ${state.offset} out of ${state.maxPage}`}</p>
                      <p className="f-14 text-muted float-end mt-1">{`Total Records: ${totalRecords}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ParameterConstants;
