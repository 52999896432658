import React, { useState, useEffect, useRef, useReducer } from "react";
import { Edit } from "react-feather";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import { Modal, Pagination, Spinner } from "react-bootstrap";
import { calMaxPage } from "../../../common/Functions/CommonFunctions";
import { update_ParameteConstant } from "../../../api";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Constant name is required !"),
    identifier: yup
      .string()
      .required("Identifier name is required !")
      .matches(/^[A-Z]+$/, "Only capital letters are allowed"),
    unit: yup.string().required("Unit is required"),
    constValue: yup
      .string()
      .required("Value is required !")
      .matches(/^\d+(\.\d{1,2})?$/, "Invalid value"),
  })
  .required();

function EditParameterConstants(props) {
  let navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [loading, setLoading] = useState(false);

  const { register, handleSubmit, control, formState, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  let { errors } = formState;

  useEffect(() => {
    if (visible) {
      reset();
    }
  }, [visible]);

  const onSubmit = (data) => {
    console.log("data", data);
    if (data !== "") {
      
      if(data.identifier === 'BSPC' && parseFloat(data.constValue) < 100000 ){
        toast.error("Boiler steam production capacity above or equal to 100000 Kg/h !", {
          autoClose: 2000,
        });
        return
      }
      setLoading(true);
      const payload = {
        id: props.data?.id,
        customerid: props.data?.customerId,
        // vessel_admin: props.data?.user_id,
        name: data.name,
        identifier: data.identifier,
        unit: data.unit,
        value: parseFloat(data.constValue),
      };

      console.log("payload", payload, "id", props.data.id);

      update_ParameteConstant(payload, { id: props.data?.id }).then(
        (res) => {
          console.log("res", res);
          if (res.status === 200 || res.status === 201) {
            toast.success("Parameter constant record updated Successfully !", {
              autoClose: 2000,
            });
            props.fetchConstants();
            setLoading(false);
            setVisible(false);
            reset();
          }
        },
        (err) => {
          console.log("err.response.status", err.response.status);
          if (err.response.status === 400) {
            console.log("err.response", err.response);
            toast.error("Parameter constant record already exist ...", {
              autoClose: 2000,
            });
            setVisible(false);
            setLoading(false);
            reset();
          }
        }
      );
    } else {
      toast.error("Something went wrong, please try again !", {
        autoClose: 1000,
      });
    }
  };

  return (
    <>
      <Edit
        height="18"
        width="18"
        className="cursor-pointer me-1"
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true);
        }}
      />
      {/* Edit Parameter Modal*/}
      <Modal show={visible} onHide={() => setVisible(false)} size={"lg"}>
        <Modal.Header className="bg-primary-blue" closeButton>
          <Modal.Title>{"Edit Constants"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Constant Name"}</label>
                <input
                  className={
                    errors.name
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.name}
                  name="name"
                  id="name"
                  {...register("name")}
                />

                <span className="text-danger">{errors.name?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Identifier"}</label>
                <input
                  className={
                    errors.identifier
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.identifier}
                  name="identifier"
                  id="identifier"
                  {...register("identifier")}
                />

                <span className="text-danger">
                  {errors.identifier?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Unit"}</label>
                <input
                  className={
                    errors.unit
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.unit}
                  name="unit"
                  id="unit"
                  {...register("unit")}
                />

                <span className="text-danger">{errors.unit?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Value"}</label>
                <input
                  className={
                    errors.constValue
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.value}
                  name="constValue"
                  id="constValue"
                  {...register("constValue")}
                />

                <span className="text-danger">
                  {errors.constValue?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setVisible(false)}>
            Close
          </button>
          <button className="btn btn-primary-blue" onClick={handleSubmit(onSubmit)}>
            {loading && <Spinner animation="border" variant="dark" size="sm" />}
            {" Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditParameterConstants;
