import React, { useState, useEffect, useRef, useReducer } from 'react'
import { Info } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import * as XLSX from 'xlsx'
import FileSaver from 'file-saver'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Modal } from 'react-bootstrap'
import { save_boiler_spec } from '../../../api'
import BoilerSpecTemplate from '../../../common/TemplateJson/BoilerSpecTemplate.json'

function BoilerSpec(props) {
  let navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const { register, handleSubmit, control, formState, reset } = useForm({
    mode: 'onChange',
  })

  let { errors } = formState

  console.log('props', props.data)

  function handleClick() {
    const worksheet = XLSX.utils.json_to_sheet([], {
      header: BoilerSpecTemplate.map((item) => item.key),
    })
    const workbook = XLSX.utils.book_new()
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Boiler Specifications')
    const excelBuffer = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' })
    const blob = new Blob([excelBuffer], { type: 'application/octet-stream' })
    FileSaver.saveAs(blob, 'Boiler_Specifications.xlsx')
    props.fetchBoilers({ vesselid: props.vesselid })
  }

  function parseExcelFile(file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader()
      reader.onload = (event) => {
        try {
          const workbook = XLSX.read(event.target.result, { type: 'binary' })
          const worksheet = workbook.Sheets[workbook.SheetNames[0]]
          const data = XLSX.utils.sheet_to_json(worksheet, { header: 1 })
          const formattedData = data.slice(1).map((row) => {
            return {
              boiler_id: props.data?.id,
              boiler_loading: row[0],
              steam_production_rate: row[1],
              fuel_consumption: row[2],
              efficiency: row[3],
            }
          })

          // console.log('formattedData',formattedData);
          resolve(formattedData)
        } catch (error) {
          reject(error)
        }
      }
      reader.readAsBinaryString(file)
    })
  }

  const fileInputRef = useRef(null)

  function resetFileInput() {
    fileInputRef.current.value = ''
  }

  function handleFileUpload(event) {
    const file = event.target.files[0]
    parseExcelFile(file)
      .then((data) => {
        // Insert the formatted data into the NeDB database
        // using your NeDB API calls
        console.log(data)

        if (data.length !== 100) {
          toast.error('Boiler specification file is incomplete, try again !', {
            autoClose: 1000,
          })
          resetFileInput()
          return
        }

        save_boiler_spec(data).then(
          (res) => {
            console.log("res", res);
            if (res.status === 200 || res.status === 201) {
              toast.success('Boiler specification upload successfully !', {
                autoClose: 2000,
              })
              props.fetchBoilers({ vesselid: props.vesselid })
              setLoading(false)
              setVisible(false)
            }
          },
          (err) => {
            console.log('err', err);
            if (err.response.status === 400) {
              toast.error('Something wents wrong ...', {
                autoClose: 2000,
              })
              setLoading(false)
              setVisible(false)
              reset()
            }
          },
        )

      })
      .catch((error) => {
        console.error(error)
      })
  }

  return (
    <>
      <Info
        className="cursor-pointer"
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true)
        }}
      />
      {/* Boiler Specifications */}
      <Modal show={visible} onHide={() => setVisible(false)} size={'lg'}>
        <Modal.Header className="bg-primary-blue" closeButton>
          <Modal.Title>{'Boiler Specification'}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">
            <div className="d-flex justify-content-between col-12 col-md-12">
              <div className="col-md-6">
                <input
                  type="file"
                  id="formFile"
                  className='form-control'
                  accept=".xlsx,.xls,.csv,.ods"
                  ref={fileInputRef}
                  onChange={handleFileUpload}
                />
              </div>
              {/* <div className="col-md-3">
                      <button
                        className="btn bg-primary-blue ms-4"
                      >
                        {'Upload'}
                      </button>
                    </div> */}
              <div className="col-md-6 text-end">
                <button className="btn bg-primary-blue" onClick={handleClick}>
                  {'Template Boiler Specification'}
                </button>
              </div>
            </div>
          </form>
        </Modal.Body>
        {/* <CModalFooter>
          <CButton color="secondary" onClick={() => setVisible(false)}>
            Close
          </CButton>
          <CButton color="btn btn-primary-blue" disabled={loading} onClick={handleSubmit(onSubmit)}>
            {loading && <CSpinner color="primary" size="sm" />}
            {' Save Changes'}
          </CButton>
        </CModalFooter> */}
      </Modal>
    </>
  )
}
export default BoilerSpec
