import React, { useState, useEffect } from "react";
import { Eye } from "react-feather";
import { Modal } from "react-bootstrap";
import { useForm, Controller } from "react-hook-form";

function TankInfo(props) {
  const [visible, setVisible] = useState(false);

  return (
    <>
      <Eye
        className="cursor_pointer"
        onClick={() => {
          setVisible(true);
          // setShowTank(val)
        }}
      />
      <React.Fragment>
        <Modal
          show={visible}
          onHide={() => setVisible(false)}
          size="xl"
        >
          <Modal.Header className="bg-primary-blue" closeButton>
            <Modal.Title>Tank Info</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="f-14 ">
              <div className="d-flex justify-content-between mt-1">
                <div className="col-md-4">
                  <strong>Tank Name</strong>
                </div>
                <div className="col-md-2">{props.data?.tankName}</div>
                <div className="col-md-4">
                  <strong>Tank Tag</strong>
                </div>
                <div className="col-md-2">{props.data?.tag}</div>
              </div>

              <div className="d-flex justify-content-between mt-2">
                <div className="col-md-4">
                  <strong>
                    Total Volume (m<sup>3</sup>)
                  </strong>
                </div>
                <div className="col-md-2">{props.data?.totalVolume}</div>
                <div className="col-md-4">
                  <strong>Material</strong>
                </div>
                <div className="col-md-2">{props.data?.tankMaterial}</div>
              </div>

              <div className="d-flex justify-content-between mt-2">
                <div className="col-md-4">
                  <strong>Thermal Conductivity (W/m °K)</strong>
                </div>
                <div className="col-md-2">
                  {props.data?.tankThermalConductivity}
                </div>
                <div className="col-md-4">
                  <strong>Thickness (m)</strong>
                </div>
                <div className="col-md-2">{props.data?.tankTheakness}</div>
              </div>

              <div className="d-flex justify-content-between mt-2">
                <div className="col-md-4">
                  <strong>Heating Medium</strong>
                </div>
                <div className="col-md-2">{props.data?.tankHeatingMedium}</div>
                <div className="col-md-4">
                  <strong>Width (m)</strong>
                </div>
                <div className="col-md-2">{props.data?.width}</div>
              </div>

              <div className="d-flex justify-content-between mt-2">
                <div className="col-md-4">
                  <strong>Height (m)</strong>
                </div>
                <div className="col-md-2">{props.data?.height}</div>
                <div className="col-md-4">
                  <strong>Length (m)</strong>
                </div>
                <div className="col-md-2">{props.data?.length}</div>
              </div>

              <hr />
              <div>
                <table className="table table-responsive table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>{"Areas"}</th>
                      <th>{"Value"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Top Area (m<sup>2</sup>)
                      </td>
                      <td>{props.data?.topArea}</td>
                    </tr>
                    <tr>
                      <td>
                        Bottom Area (m<sup>2</sup>)
                      </td>
                      <td>{props.data?.bottomArea}</td>
                    </tr>
                    <tr>
                      <td>
                        Aft Area (m<sup>2</sup>)
                      </td>
                      <td>{props.data?.afterArea}</td>
                    </tr>
                    <tr>
                      <td>
                        Forward Area (m<sup>2</sup>)
                      </td>
                      <td>{props.data?.forwardArea}</td>
                    </tr>
                    <tr>
                      <td>
                        Port Area (m<sup>2</sup>)
                      </td>
                      <td>{props.data?.portArea}</td>
                    </tr>
                    <tr>
                      <td>
                        Starboard Area (m<sup>2</sup>)
                      </td>
                      <td>{props.data?.starboardArea}</td>
                    </tr>
                  </tbody>
                </table>
              </div>

              <hr />
              <div>
                <table className="table table-responsive table-striped table-bordered">
                  <thead>
                    <tr>
                      <th>{"Area Cases"}</th>
                      <th>{"Area Case Name"}</th>
                      <th>{"Value"}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td>
                        Top Area Case (W/m<sup>2</sup> °K)
                      </td>
                      <td>{props.data?.topAreaCase.name}</td>
                      <td>{props.data?.topAreaCase.value}</td>
                    </tr>
                    <tr>
                      <td>
                        Bottom Area Case (W/m<sup>2</sup> °K)
                      </td>
                      <td>{props.data?.bottomAreaCase.name}</td>
                      <td>{props.data?.bottomAreaCase.value}</td>
                    </tr>
                    <tr>
                      <td>
                        Aft Area Case (W/m<sup>2</sup> °K)
                      </td>
                      <td>{props.data?.afterAreaCase.name}</td>
                      <td>{props.data?.afterAreaCase.value}</td>
                    </tr>
                    <tr>
                      <td>
                        Forward Area Case (W/m<sup>2</sup> °K)
                      </td>
                      <td>{props.data?.forwardAreaCase.name}</td>
                      <td>{props.data?.forwardAreaCase.value}</td>
                    </tr>
                    <tr>
                      <td>
                        Port Area Case (W/m<sup>2</sup> °K)
                      </td>
                      <td>{props.data?.portAreaCase.name}</td>
                      <td>{props.data?.portAreaCase.value}</td>
                    </tr>
                    <tr>
                      <td>
                        Starboard Area Case (W/m<sup>2</sup> °K)
                      </td>
                      <td>{props.data?.starboardAreaCase.name}</td>
                      <td>{props.data?.starboardAreaCase.value}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              {/* <div className="d-flex">
                <h6 className="me-4">Top Area Case (W/m<sup>2</sup> °K) :</h6>
                <p className="f-w-600 me-2">{props.data?.topAreaCase.name[0].name} :</p>
                <p className="f-w-600">{props.data?.topAreaCase.value}</p>
              </div>
              <div className="d-flex">
                <h6 className="me-4">Bottom Area Case (W/m<sup>2</sup> °K) :</h6>
                <p className="f-w-600 me-2">{props.data?.bottomAreaCase.name[0].name} :</p>
                <p className="f-w-600">{props.data?.bottomAreaCase.value}</p>
              </div>
              <div className="d-flex">
                <h6 className="me-4">Aft Area Case (W/m<sup>2</sup> °K) :</h6>
                <p className="f-w-600 me-2">{props.data?.afterAreaCase.name[0].name} :</p>
                <p className="f-w-600">{props.data?.afterAreaCase.value}</p>
              </div>
              <div className="d-flex">
                <h6 className="me-4">Forward Area Case (W/m<sup>2</sup> °K) :</h6>
                <p className="f-w-600 me-2">{props.data?.forwardAreaCase.name[0].name} :</p>
                <p className="f-w-600">{props.data?.forwardAreaCase.value}</p>
              </div>
              <div className="d-flex">
                <h6 className="me-4">Port Area Case (W/m<sup>2</sup> °K) :</h6>
                <p className="f-w-600 me-2">{props.data?.portAreaCase.name[0].name} :</p>
                <p className="f-w-600">{props.data?.portAreaCase.value}</p>
              </div>
              <div className="d-flex">
                <h6 className="me-4">Starboard Area Case (W/m<sup>2</sup> °K) :</h6>
                <p className="f-w-600 me-2">{props.data?.starboardAreaCase.name[0].name} :</p>
                <p className="f-w-600">{props.data?.starboardAreaCase.value}</p>
              </div> */}
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              className="btn btn-secondary"
              onClick={() => setVisible(false)}
            >
              Close
            </button>
            {/* <CButton color="primary">Save changes</CButton> */}
          </Modal.Footer>
        </Modal>
      </React.Fragment>
    </>
  );
}

export default TankInfo;
