import React, { useState, useEffect, useRef, useReducer } from "react";
import { Edit, Trash2, ArrowLeftCircle } from "react-feather";
import { useForm, Controller } from "react-hook-form";
import { Typeahead } from "react-bootstrap-typeahead";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SweetAlert from "sweetalert2";
import EditBoiler from "./EditBoiler";
import { Modal, Pagination, Spinner } from "react-bootstrap";
import { calMaxPage } from "../../../common/Functions/CommonFunctions";
import { save_boiler, get_boilers, delete_boiler } from "../../../api";
import BoilerSpec from "../BoilerSpec/BoilerSpec";

const schema = yup
  .object()
  .shape({
    boilername: yup.string().required("Boiler name is required !"),
    boilermodel: yup.string().required("Model name is required !"),
    steamgencapacity: yup
      .string()
      .required("Steam generation capacity is required !")
      .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
    steampressure: yup
      .string()
      .required("Steam pressure is required !")
      .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
    steamtemperature: yup
      .string()
      .required("Steam temperature is required !")
      .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
  })
  .required();

function BoilerMaster() {
  let navigate = useNavigate();
  let location = useLocation();
  const [ves, setVes] = useState([]);

  const [boiler, setBoiler] = useState([]);
  const [visible, setVisible] = useState(false);
  const [apiBySearch, setApiBySearch] = useState(false);
  const [dataForUpdation, setDataForUpdation] = useState();
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, control, formState, watch, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  let { errors } = formState;

  const selVessel = watch("vessalName");

  const initialState = {
    loading: false,
    error: "",
    boilerList: [],
    limit: 10,
    offset: 0,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "HANDLE_ACTION":
        return {
          offset: action.offset,
          limit: action.limit,
          loading: action.loading,
          error: action.error,
          boilerList: action.boilerList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log("statedfd", location.state);
    dispatch({
      type: "HANDLE_ACTION",
      offset: state.offset,
      loading: true,
      error: state.error,
      boilerList: state.boilerList,
      limit: state.limit,
      maxPage: state.maxPage,
    });

    fetchBoilers();
  }, [state.offset, state.limit]);

  function fetchBoilers(para1, para2) {
    dispatch({
      type: "HANDLE_ACTION",
      offset: para1 === "refresh" ? 0 : state.offset,
      loading: true,
      error: state.error,
      boilerList: state.boilerList,
      limit: state.limit,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      offset: state.offset,
      limit: state.limit,
      vesselid: location.state.id,
    };

    if (para1 === "search") {
      reqPayload = {
        offset: state.offset,
        limit: state.limit,
        searchVal: para2,
      };
    }

    get_boilers(reqPayload).then(
      (res) => {
        console.log("res", res);
        console.log("calMaxPage(res.data.count)", calMaxPage(res.data.count));
        setTotalRecords(res.data.count);

        dispatch({
          type: "HANDLE_ACTION",
          offset: state.offset,
          loading: false,
          error: state.error,
          boilerList: res.data.data.response.results,
          limit: state.limit,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.data.response.results.length)
              : calMaxPage(res.data.count),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", { autoClose: 2000 });
        }
      }
    );
  }

  const onSubmit = (data) => {
    if (data !== "") {
      setLoading(true);
      const payload = {
        vesselid: location.state?.id,
        // boilerSelfId:
        boilername: data.boilername,
        boilermodel: data.boilermodel,
        steamgencapacity: data.steamgencapacity,
        steampressure: data.steampressure,
        steamtemperature: data.steamtemperature,
      };

      save_boiler(payload)
        .then(
          (res) => {
            console.log("res", res);
            if (res.status === 200 || res.status === 201) {
              toast.success("Boiler created Successfully !", {
                autoClose: 3000,
              });
              fetchBoilers(location.state?.id);
              setLoading(false);
              setVisible(false);
              reset();
            }
          },
          (err) => {
            console.log("err.response.status", err.response.status);
            if (err.response.status === 400) {
              console.log("err.response", err.response);
              toast.error("Boiler record already exist ...", {
                autoClose: 3000,
              });
              setLoading(false);
              setVisible(false);
              reset();
            }
          }
        )
        .catch((error) => {
          console.log("error", error);
          toast.error("Something went wrong, please try again !", {
            autoClose: 3000,
          });
        });
    } else {
      toast.error("Something went wrong, please try again !", {
        autoClose: 1000,
      });
    }
  };

  // Deleting Boiler
  const delBoiler = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure? \n" + val.boilerName,
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        console.log("val", val);

        delete_boiler({ id: val }).then(
          (res) => {
            console.log("res", res);
            toast.success("Boiler record deleted successfully !", {
              autoClose: 5000,
            });

            fetchBoilers();
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", { autoClose: 5000 });
          }
        );
      }
    });
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Boiler Modal*/}
      <Modal show={visible} onHide={() => setVisible(false)} size={"lg"}>
        <Modal.Header className="bg-primary-blue" closeButton>
          <Modal.Title>Add Boiler</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Boiler Name"}</label>
                <input
                  className={
                    errors.boilername
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter boiler name"
                  name="boilername"
                  {...register("boilername")}
                />

                <span className="text-danger">
                  {errors.boilername?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Model Detail Number"}</label>
                <input
                  className={
                    errors.boilermodel
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter model detail"
                  name="boilermodel"
                  {...register("boilermodel")}
                />

                <span className="text-danger">
                  {errors.boilermodel?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">
                  {"Steam Generation capacity"}
                  {"(Kg/h)"}
                </label>
                <input
                  className={
                    errors.steamgencapacity
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter steam gen capacity"
                  name="steamgencapacity"
                  {...register("steamgencapacity")}
                />

                <span className="text-danger">
                  {errors.steamgencapacity?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">
                {"Steam Pressure"}
                {"(barg)"}
                </label>
                <input
                  className={
                    errors.steampressure
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter steam pressure"
                  name="steampressure"
                  {...register("steampressure")}
                />

                <span className="text-danger">
                  {errors.steampressure?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">
                {"Steam Temperature"}
                {"(°C)"}
                </label>
                <input
                  className={
                    errors.steamtemperature
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter steam temperature"
                  name="steamtemperature"
                  {...register("steamtemperature")}
                />

                <span className="text-danger">
                  {errors.steamtemperature?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setVisible(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary-blue"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            {loading && <Spinner animation='border' variant="dark" size="sm" />}
            {" Save"}
          </button>
        </Modal.Footer>
      </Modal>

      <div className="py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card_title bg-primary-blue">
                  <h5 className="m-0">
                    <ArrowLeftCircle
                      className="me-2 cursor-pointer"
                      onClick={() => navigate(-1)}
                    />
                    {" Boiler"} - {location.state?.vesselName}
                  </h5>
                </div>

                <div className="card-body">
                  {/* <CreateVessalMaster /> */}
                  <div className="d-flex justify-content-end col-md-12">
                    {/* <input
                          style={{ maxWidth: '300px' }}
                          className="form-control"
                          type="text"
                          name="search"
                          placeholder="Search"

                          // checking if search value is not empty then only calling the function
                          // onChange={(e) => {
                          //     if (e.target.value.length > 0) {
                          //         fetchBoilers('search', e.target.value);
                          //         setApiBySearch(true);
                          //     } else {
                          //         fetchBoilers();
                          //     }

                          // }}
                        /> */}
                    <button
                      className="btn bg-primary-blue "
                      type="submit"
                      onClick={() => setVisible(!visible)}
                    >
                      {"Add Boiler"}
                    </button>
                  </div>

                  {/* {state.loading ? ( */}

                  {/* ) : state.boilerList.length > 0 ? ( */}

                  <div className="table-responsive">
                    <table
                      className="table table-bordered mt-4 table-striped"
                      width={"100%"}
                    >
                      <thead className="thead-light">
                        <tr className="f-16 text-center">
                          <th scope="col">{"Sr"}</th>
                          <th scope="col">{"Boiler"}</th>
                          <th scope="col">{"Model"}</th>
                          <th scope="col">
                            {"Steam generation capacity"}
                            <br></br>
                            {"(Kg/h)"}
                          </th>
                          <th scope="col">
                            {"Steam Pressure"}
                            <br></br>
                            {"(barg)"}
                          </th>
                          <th scope="col">
                            {"Steam Temperature"}
                            <br></br>
                            {"(°C)"}
                          </th>
                          <th scope="col">{"Actions"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.loading ? (
                          <Spinner color="primary" size="lg" />
                        ) : (
                          state.boilerList.map((val, i) => (
                            <tr key={i} className="f-16 text-center">
                              <td>{i + srNo + 1}</td>
                              <td>{val.boilerName}</td>
                              <td>{val.boilerModel}</td>
                              <td>{val.steamGenCapacity}</td>
                              <td>{val.steamPressure}</td>
                              <td>{val.steamTemperature}</td>
                              <td>
                                <EditBoiler
                                  fetchBoilers={fetchBoilers}
                                  data={val}
                                  vesselid={location.state.id}
                                />
                                <Trash2
                                  className="cursor-pointer"
                                  onClick={() => delBoiler(val)}
                                />

                                <BoilerSpec
                                  fetchBoilers={fetchBoilers}
                                  data={val}
                                  vesselid={location.state.id}
                                />
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>

                  {state.boilerList.length == 0 && (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"No Boiler Found !"}
                    </p>
                  )}
                </div>

                {/* {state.loading ? null : (
                    <div className="card-body p-0">
                      <div className="mt-4">
                        <CPagination
                          className="justify-content-end mb-0"
                          aria-label="Page navigation example"
                        >
                          <CPaginationItem
                            disabled={state.offset === 1 ? true : false}
                            className="cursor_pointer"
                            onClick={() => {
                              dispatch({
                                type: 'HANDLE_ACTION',
                                offset: state.offset - 1,
                                loading: state.loading,
                                error: state.error,
                                boilerList: state.boilerList,
                                limit: state.limit,
                                maxPage: state.maxPage,
                              })
                              setSrNo((prevC) => prevC - 10)
                            }}
                          >
                            {'Previous'}
                          </CPaginationItem>
                          <CPaginationItem active>{state.offset}</CPaginationItem>
                          <CPaginationItem
                            disabled={
                              state.offset === state.maxPage || state.maxPage === 0 ? true : false
                            }
                            className="cursor_pointer"
                            onClick={() => {
                              dispatch({
                                type: 'HANDLE_ACTION',
                                offset: state.offset + 1,
                                loading: state.loading,
                                error: state.error,
                                boilerList: state.boilerList,
                                limit: state.limit,
                                maxPage: state.maxPage,
                              })
                              setSrNo((prevC) => prevC + 10)
                            }}
                          >
                            {'Next'}
                          </CPaginationItem>
                        </CPagination>

                        <p className="f-14 text-muted float-start mt-1">{`Page: ${state.offset} out of ${state.maxPage}`}</p>
                        <p className="f-14 text-muted float-end mt-1">{`Total Records: ${totalRecords}`}</p>
                      </div>
                    </div>
                  )} */}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default BoilerMaster;
