import React, { useState, useEffect } from "react";
import {
  Edit,
  Trash2,
  Eye,
  EyeOff,
  Download,
  ArrowLeftCircle,
} from "react-feather";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import {
  get_users,
  save_user,
  delete_user,
  get_vessels,
  get_vessel_admin,
  save_vessel_admin,
  delete_vessel_admin,
} from "../../api";
import { Modal, Pagination, Spinner } from "react-bootstrap";
import EditVesselAdmin from "./EditVesselAdmin";
import { calMaxPage } from "../../common/Functions/CommonFunctions";
const schema = yup
  .object()
  .shape({
    name: yup.string().required("Name is required !"),
    email: yup.string().required("Email is required !"),
    username: yup.string().required("Username is required"),
    password: yup.string().required("Password is required !"),
    vessel: yup.array().required("Please select vessel !"),
    rank: yup.string().required("Rank is required !"),
  })
  .required();
function VesselAdminMaster() {
  let navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [vesseladmins, setVesseladmins] = useState({ data: [], loading: true });

  const [showPassword, setShowPassword] = useState(false);
  const [sameAsEmail, setSameAsEmail] = useState(false);

  const [vesselList, setVesselList] = useState({ data: [], state: false });

  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const [maxPage, setMaxPage] = useState(0);
  const [page, setPage] = useState(1);
  const [limit, setLimit] = useState(10);
  const [loading, setLoading] = useState(false);

  const userData = JSON.parse(localStorage.getItem("userInfo"));

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  let { errors } = formState;

  useEffect(() => {
    console.log("sameAsEmail", sameAsEmail);
    if (sameAsEmail) {
      const email = getValues("email");
      setValue("username", email);
    }
  }, [sameAsEmail]);

  useEffect(() => {
    if (visible == true) {
      reset();
    }
  }, [visible]);

  useEffect(() => {
    fetchvesseladmins(page);
    fetchVessels();
  }, []);

  function fetchVessels() {
    setVesselList({ ...vesselList, data: [], state: true });
    const payload = {
      offset: 0,
      limit: 10,
    };

    get_vessels(payload).then(
      (res) => {
        console.log("res", res);
        // const data = res.data.data
        // console.log('data', data)
        // if (res.data.statusCode == 200) {
        setVesselList({
          ...vesselList,
          data: res.data.data?.response?.results,
          state: false,
        });
        // }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  const fetchvesseladmins = (val, para) => {
    setVesseladmins({ data: [], loading: true });
    let params = {};
    if (val !== null) {
      params = {
        offset: val - 1,
        limit: limit,
      };
    } else {
      params.search = para;
    }

    console.log("param", params);

    get_vessel_admin(params)
      .then((res) => {
        if (res) {
          //  console.log("Vesseladmin", res)
          setVesseladmins({
            data: res.data.data.response.results,
            loading: false,
          });
          setMaxPage(calMaxPage(res.data.data.response.count.totalData));
          setTotalRecords(res.data.data.response.count.totalData);
        } else {
          setVesseladmins({ data: [], loading: true });
        }
      })
      .catch((er) => console.log("Something went wrong"));
  };

  const onSubmit = (data) => {
    //  console.log('data', data)
    // console.log("vesselnmae",data.vessel[0].vesselName)
    if (data !== "") {
      setLoading(true);

      let payload = {
        name: data.name,
        email: data.email,
        username: data.username,
        password: data.password,
        website: "",
        address: "",
        contactnumber: "",
        customertype: "",
        country: "",
        state: "",
        city: "",
        signature: "",
        vesselid: data.vessel[0].id,
        vesselname: data.vessel[0].vesselName,
        rank: data.rank,
      };

      //console.log('data', payload)
      save_vessel_admin(payload).then(
        (res) => {
          console.log("res", res);
          toast.success("Vessel admin  added successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          fetchvesseladmins();
          setLoading(false);

          setVisible(false);
          reset();
        },
        (err) => {
          console.log("err", err);
          setLoading(false);
          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  function delVesselAdmin(val) {
    SweetAlert.fire({
      title: "Are you sure to delete? \n" + val.name,
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        delete_vessel_admin({ id: val.id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Vessel Admin deleted successfully", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
            fetchvesseladmins();
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", {
              position: "top-right",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "light",
            });
          }
        );
      }
    });
  }

  const downloadJson = async (val) => {
    //console.log("val",val)

    const payload = {
      vesselName: val.vesselname,
      password: val.password,
      role: val.role,
      vesselId: val.vesselid,
      username: val.username,
      id: val.id,
      email: val.email,
      name: val.name,
      role_name: val.role_name,
      rank: val.rank,
      customerId: userData.customerid,
    };
    console.log("payload", payload);

    if (Object.keys(val).length > 0) {
      const json = await JSON.stringify(payload);
      // console.log('json', json)
      const blob = new Blob([json], { type: "application/json" });
      const url = URL.createObjectURL(blob);
      const fileName = val.name + "_" + val.vesselid + ".json";
      const a = document.createElement("a");
      a.href = url;
      a.download = fileName;
      a.click();
      URL.revokeObjectURL(url);
    } else {
      return null;
    }
  };

  return (
    <div className="mb-4">
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="card">
        <div className="card_title poppins-bold bg-primary-blue">
          <h5 className="mb-0">
            <ArrowLeftCircle className="me-2" onClick={() => navigate(-1)} />
            {" Vessel Admin List"}
          </h5>
        </div>
        <div className="card-body">
          <div className="row justify-content-between">
            <div className="col-12 col-md-4">
              <input
                style={{ maxWidth: "300px" }}
                className="form-control"
                type="text"
                name="search"
                placeholder="Search"
                onChange={(e) => {
                  fetchvesseladmins(null, e.target.value);
                }}
              />
            </div>

            <div className="col-12 col-md-3">
              <button
                className="btn btn-primary-blue w-100"
                onClick={() => setVisible(!visible)}
              >
                Create Vessel Admin
              </button>
            </div>

            <div className="table-responsive" style={{ minHeight: "40vh" }}>
              <table
                className="table table-bordered mt-4 table-striped"
                width={"100%"}
              >
                <thead className="thead-light">
                  <tr className="f-16 text-center">
                    <th scope="col">{"Sr"}</th>
                    <th scope="col">{"Vessel Admin Name"}</th>
                    <th scope="col">{"Email"}</th>
                    <th scope="col">{"Vessel Name"}</th>
                    <th scope="col">{"Rank"}</th>
                    <th>{"Action"}</th>
                  </tr>
                </thead>
                <tbody>
                  {vesseladmins.loading ? (
                    <Spinner animation="border" variant="dark" role="status" />
                  ) : (
                    vesseladmins?.data?.map((val, i) => (
                      <tr key={i} className=" text-center f-16">
                        <td>{i + 1 + srNo}</td>
                        <td>{val.name}</td>
                        <td>{val.email}</td>
                        <td>{val.vesselname}</td>
                        <td>{val.rank}</td>
                        <td>
                          <EditVesselAdmin
                            fetchVessels={fetchVessels}
                            data={val}
                            fetchvesseladmins={fetchvesseladmins}
                          />
                          <Trash2
                            className="cursor-pointer me-2"
                            onClick={() => delVesselAdmin(val)}
                          />
                          <Download
                            className="cursor-pointer me-2"
                            onClick={() => downloadJson(val)}
                          />
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </table>
              <div className="card-body p-0">
                <div className="mt-4">
                  <Pagination
                    className="justify-content-end mb-0"
                    aria-label="Page navigation example"
                  >
                    <Pagination.Item
                      disabled={page === 1 ? true : false}
                      className="cursor_pointer"
                      onClick={() => {
                        fetchvesseladmins(page - 1);
                        setPage((prev) => prev - 1);

                        setSrNo((prevC) => prevC - 10);
                      }}
                    >
                      {"Previous"}
                    </Pagination.Item>
                    <Pagination.Item active>{page}</Pagination.Item>
                    <Pagination.Item
                      disabled={
                        page === maxPage || maxPage === 0 ? true : false
                      }
                      className="cursor_pointer"
                      onClick={() => {
                        fetchvesseladmins(page + 1);
                        setPage((prev) => prev + 1);
                        setSrNo((prevC) => prevC + 10);
                      }}
                    >
                      {"Next"}
                    </Pagination.Item>
                  </Pagination>

                  <p className="f-14 text-muted float-start mt-1">{`Page: ${page} out of ${maxPage}`}</p>
                  <p className="f-14 text-muted float-end mt-1">{`Total Records: ${totalRecords}`}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal show={visible} onHide={() => setVisible(false)} size="lg">
        <Modal.Header className="bg-primary-blue" closeButton>
          <Modal.Title className="">Create Vessel Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Name"}</label>
                <input
                  className={
                    errors.name ? "form-control is-invalid" : "form-control"
                  }
                  type="text"
                  placeholder="Name*"
                  name="name"
                  {...register("name")}
                />

                <span className="text-danger">{errors.name?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Rank"}</label>
                <input
                  className={
                    errors.rank ? "form-control is-invalid" : "form-control"
                  }
                  type="text"
                  placeholder="Rank*"
                  name="rank"
                  {...register("rank")}
                />

                <span className="text-danger">{errors.rank?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Email"}</label>
                <input
                  className={
                    errors.email ? "form-control is-invalid" : "form-control"
                  }
                  type="email"
                  name="email"
                  placeholder="Email Address*"
                  {...register("email")}
                />

                <span className="text-danger">{errors.email?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Username"}</label>
                <input
                  className={
                    errors.username ? "form-control is-invalid" : "form-control"
                  }
                  type="text"
                  name="username"
                  placeholder="Username*"
                  {...register("username")}
                />
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    checked={sameAsEmail}
                    onChange={(e) => setSameAsEmail(e.target.checked)}
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    style={{ fontSize: "13px", color: "gray" }}
                    className="form-check-label"
                    htmlFor="exampleCheck1"
                  >
                    Same As Email
                  </label>
                </div>
                <span className="text-danger">{errors.username?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Select Vessel"}</label>
                <Controller
                  name="vessel"
                  control={control}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="vesselName"
                      multiple={false}
                      options={vesselList.data}
                      isLoading={vesselList.state}
                      //   isLoading={locations.state}
                      placeholder="Select Vessel"
                      onChange={onChange}
                      onBlur={onBlur}
                      selected={value}
                      isInvalid={errors.vessel ? true : false}
                    />
                  )}
                />
                <span className="text-danger">{errors.vessel?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Password"}</label>
                <div className="input-group">
                  <input
                    className={
                      errors.password
                        ? "form-control is-invalid"
                        : "form-control"
                    }
                    type={showPassword ? "text" : "password"}
                    placeholder="Password*"
                    name="password"
                    {...register("password")}
                  />
                  <div className="input-group-append">
                    {showPassword ? (
                      <span className="input-group-text">
                        <EyeOff
                          size={18}
                          className="cursor-pointer my-1"
                          onClick={() => setShowPassword(false)}
                        />
                      </span>
                    ) : (
                      <span className="input-group-text">
                        <Eye
                          size={18}
                          className="cursor-pointer my-1"
                          onClick={() => setShowPassword(true)}
                        />
                      </span>
                    )}
                  </div>
                </div>

                <span className="text-danger">{errors.password?.message}</span>
              </div>
            </div>

            {/* <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Website'}</label>
                  <input
                    className={errors.website ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    name="website"
                    placeholder="Website*"
                    {...register('website')}
                  />

                  <span className="text-danger">{errors.website?.message}</span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{'Address'}</label>
                  <input
                    className={errors.address ? 'form-control is-invalid' : 'form-control'}
                    type="text"
                    name="address"
                    placeholder="Address*"
                    {...register('address')}
                  />

                  <span className="text-danger">{errors.address?.message}</span>
                </div>
              </div> */}
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setVisible(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary-blue"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            {loading && <Spinner animation="border" variant="dark" size="sm" />}
            {" Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

export default VesselAdminMaster;
