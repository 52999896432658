import React, { useState, useEffect, useRef, useReducer } from "react";
import { Edit } from "react-feather";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Modal, Pagination, Spinner } from "react-bootstrap";
import PropTypes from "prop-types";
import { Typeahead } from "react-bootstrap-typeahead";
import { calMaxPage } from "../../../common/Functions/CommonFunctions";
import { update_tank } from "../../../api";

const schema = yup.object().shape({
  tankname: yup.string().required("Tank name is required !"),
  tag: yup.string().required("Tag name is required !"),
  side: yup.string().required("Side is required !"),
  sequenceno: yup.string().required("Sequence no is required !"),
  totalvolume: yup
    .string()
    .required("Total volume is required !")
    .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
  tankmaterial: yup.string().required("Tank material is required !"),
  tankthermalconductivity: yup
    .string()
    .required("Tank thermal conductivity is required !")
    .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
  tanktheakness: yup
    .string()
    .required("Tank thickness is required !")
    .matches(/^\d+(\.\d+)?$/, "No character input allowd")
    .test("zero-or-less", "Tank thickness must be greater than 0.", function (value) {
      // 'value' contains the input value of tanktheakness
      return parseFloat(value) > 0;
    })
    .test("maximum-thickness", "Tank thickness cannot above 0.015.", function (
      value
    ) {
      // 'value' contains the input value of tanktheakness
      return parseFloat(value) <= 0.015;
    }),
  tankheatingmedium: yup
    .string()
    .required("Tank heating medium is required !"),
  width: yup
    .string()
    .required("Width is required !")
    .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
  height: yup
    .string()
    .required("Heigth is required !")
    .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
  length: yup
    .string()
    .required("Length is required !")
    .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
  topareacase: yup
    .array()
    .required("Top area case is required")
    .min(1, "Select atleast one case"),
  topAreaCaseValue: yup
    .string()
    .required("Top area case value is required")
    .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
  bottomareacase: yup
    .array()
    .required("Bottom area case is required")
    .min(1, "Select atleast one case"),
  bottomAreaCaseValue: yup
    .string()
    .required("Bottom area case value is required")
    .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
  afterareacase: yup
    .array()
    .required("Aft area case is required")
    .min(1, "Select atleast one case"),
  afterAreaCaseValue: yup
    .string()
    .required("Aft area case value is required")
    .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
  forwardareacase: yup
    .array()
    .required("Forward area case is required")
    .min(1, "Select atleast one case"),
  forwardAreaCaseValue: yup
    .string()
    .required("Forward area case value is required")
    .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
  portareacase: yup
    .array()
    .required("Port area case is required")
    .min(1, "Select atleast one case"),
  portAreaCaseValue: yup
    .string()
    .required("Port area case value is required")
    .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
  starboardareacase: yup
    .array()
    .required("Starboard area case is required")
    .min(1, "Select atleast one case"),
  starboardAreaCaseValue: yup
    .string()
    .required("Starboard area case value is required")
    .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
})
.required();

function EditTank(props) {
  let navigate = useNavigate();

  const [visible, setVisible] = useState(false);
  const [fdata, setFdata] = useState({ ...props.data });
  const [loading, setLoading] = useState(false);
  const {
    register,
    handleSubmit,
    control,
    formState,
    setValue,
    getValues,
    reset,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  let { errors } = formState;

  const selTopAreaCase = watch("topareacase");
  const selBottomAreaCase = watch("bottomareacase");
  const selAfterAreaCase = watch("afterareacase");
  const selForwardAreaCase = watch("forwardareacase");
  const selPortAreaCase = watch("portareacase");
  const selStarboardAreaCase = watch("starboardareacase");

  const areaCases = [
    {
      name: "Heat Transfer coefficient between tank top BHD & air",
      identifier: "TTBA",
    },
    {
      name: "Heat Transfer coefficient between tank side BHD & air",
      identifier: "TSBA",
    },
    {
      name: "Heat Transfer coefficient between tank bottom BHD & sea water",
      identifier: "TBSW",
    },
    { name: "Heat Transfer coefficient between tank & oil", 
      identifier: "TO" 
    },
    {
      name: "Heat Transfer coefficient between tank & void space fluid",
      identifier: "TVSF",
    },
    {
      name: "Heat Transfer coefficient between tank side BHD & sea water",
      identifier: "TSBSW",
    },
    {
      name: "Heat Transfer coefficient between tank & insulated wall fluid",
      identifier: "TIWF",
    }
  ];

  useEffect(() => {
    console.log(props.data);
    reset();
  }, [visible]);

  const onSubmit = (data) => {
    if (data !== "") {
      // console.log('data', data)
      setLoading(true);
      const payload = {
        vesselid: props.data?.vesselId,
        // id: props.data?.id,
        // tankSelfId: "1",
        tankname: data.tankname, //"NO.1 C.O. TK (P)",
        tag: data.tag, //"NO.1 C.O. TK (P)",
        side: data.side, //"P",
        sequenceno: data.sequenceno, //1,
        totalvolume: data.totalvolume, //5763.7,
        tankmaterial: data.tankmaterial, //"Steel",
        tankthermalconductivity: data.tankthermalconductivity, // 50,
        tanktheakness: data.tanktheakness, //0.012,
        tankheatingmedium: data.tankheatingmedium, //"steam",
        width: data.width, //14.04,
        height: data.height, // 18.5,
        length: data.length, //27.2,
        toparea: data.toparea, //381.89,
        bottomarea: data.bottomarea, //381.89,
        afterarea: data.aftarea, //259.74,
        forwardarea: data.forwardarea, //259.74,
        portarea: data.portarea, //503.20,
        starboardarea: data.starboardarea, //503.20,
        topareacase: {
          name: data.topareacase[0].name,
          value: data.topAreaCaseValue,
          identifier: data.topareacase[0].identifier,
        },
        bottomareacase: {
          name: data.bottomareacase[0].name,
          value: data.bottomAreaCaseValue,
          identifier: data.bottomareacase[0].identifier,
        },
        afterareacase: {
          name: data.afterareacase[0].name,
          value: data.afterAreaCaseValue,
          identifier: data.afterareacase[0].identifier,
        },
        forwardareacase: {
          name: data.forwardareacase[0].name,
          value: data.forwardAreaCaseValue,
          identifier: data.forwardareacase[0].identifier,
        },
        portareacase: {
          name: data.portareacase[0].name,
          value: data.portAreaCaseValue,
          identifier: data.portareacase[0].identifier,
        },
        starboardareacase: {
          name: data.starboardareacase[0].name,
          value: data.starboardAreaCaseValue,
          identifier: data.starboardareacase[0].identifier,
        },
      };

      console.log("payloadanlsd", payload);

      // console.log('id', props.data?.id)

      update_tank(payload, { id: props.data?.id })
        .then(
          (res) => {
            console.log("res", res);

            if (res.status === 200 || res.status === 201) {
              toast.success("Tank record updated Successfully !", {
                autoClose: 3000,
              });
              props.fetchTanks(props.data?.vesselId);
              setLoading(false);
              setVisible(false);
              reset();
            }
          },
          (err) => {
            console.log("err.response.status", err.response.status);
            if (err.response.status === 400) {
              console.log("err.response", err.response);
              toast.error("Tank record already exist ...", {
                autoClose: 3000,
              });
              setLoading(false);
              setVisible(false);
              reset();
            }
          }
        )
        .catch((error) => {
          console.log("error", error);
          toast.error("Something went wrong, please try again !", {
            autoClose: 3000,
          });
        });
    } else {
      toast.error("Something went wrong, please try again !", {
        autoClose: 1000,
      });
    }
  };

  var len, widt, higt;

  function handleChangeLength(e) {
    len = e.target.value;
    widt = getValues("width");
    higt = getValues("height");
    if (len !== "" && widt !== "" && higt !== "") {
      let res1 = parseFloat(len) * parseFloat(widt);
      let res2 = parseFloat(widt) * parseFloat(higt);
      let res3 = parseFloat(len) * parseFloat(higt);

      setValue("toparea", parseFloat(res1).toFixed(2));
      setValue("bottomarea", parseFloat(res1).toFixed(2));
      setValue("aftarea", parseFloat(res2).toFixed(2));
      setValue("forwardarea", parseFloat(res2).toFixed(2));
      setValue("portarea", parseFloat(res3).toFixed(2));
      setValue("starboardarea", parseFloat(res3).toFixed(2));
    }
  }

  function handleChangeWidth(e) {
    widt = e.target.value;
    len = getValues("length");
    higt = getValues("height");
    if (len !== "" && widt !== "" && higt !== "") {
      let res1 = parseFloat(len) * parseFloat(widt);
      let res2 = parseFloat(widt) * parseFloat(higt);
      let res3 = parseFloat(len) * parseFloat(higt);

      setValue("toparea", parseFloat(res1).toFixed(2));
      setValue("bottomarea", parseFloat(res1).toFixed(2));
      setValue("aftarea", parseFloat(res2).toFixed(2));
      setValue("forwardarea", parseFloat(res2).toFixed(2));
      setValue("portarea", parseFloat(res3).toFixed(2));
      setValue("starboardarea", parseFloat(res3).toFixed(2));
    }
  }

  function handleChangeHeight(e) {
    higt = e.target.value;
    widt = getValues("width");
    len = getValues("length");

    if (len !== "" && widt !== "" && higt !== "") {
      let res1 = parseFloat(len) * parseFloat(widt);
      let res2 = parseFloat(widt) * parseFloat(higt);
      let res3 = parseFloat(len) * parseFloat(higt);

      setValue("toparea", parseFloat(res1).toFixed(2));
      setValue("bottomarea", parseFloat(res1).toFixed(2));
      setValue("aftarea", parseFloat(res2).toFixed(2));
      setValue("forwardarea", parseFloat(res2).toFixed(2));
      setValue("portarea", parseFloat(res3).toFixed(2));
      setValue("starboardarea", parseFloat(res3).toFixed(2));
    }
  }

  return (
    <>
      <Edit
        className="cursor-pointer"
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true);
        }}
      />
      {/* Edit Item Modal*/}
      <Modal show={visible} onHide={() => setVisible(false)} size={"lg"}>
        <Modal.Header className="bg-primary-blue" closeButton>
          <Modal.Title>{"Edit Tank"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">
            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Constant Name"}</label>
                <input
                  className={
                    errors.tankname
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.tankName}
                  name="tankname"
                  id="tankname"
                  {...register("tankname")}
                />

                <span className="text-danger">{errors.tankname?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Tag"}</label>
                <input
                  className={
                    errors.tag
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.tag}
                  name="tag"
                  id="tag"
                  {...register("tag")}
                />

                <span className="text-danger">{errors.tag?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-2">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Side"}</label>
                <input
                  className={
                    errors.side
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.side}
                  name="side"
                  id="side"
                  {...register("side")}
                />

                <span className="text-danger">{errors.side?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-2">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Sequence No"}</label>
                <input
                  className={
                    errors.sequenceno
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.sequenceNo}
                  name="sequenceno"
                  id="sequenceno"
                  {...register("sequenceno")}
                />

                <span className="text-danger">
                  {errors.sequenceno?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Total Volume (m3)"}</label>
                <input
                  className={
                    errors.totalvolume
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.totalVolume}
                  name="totalvolume"
                  id="totalvolume"
                  {...register("totalvolume")}
                />

                <span className="text-danger">
                  {errors.totalvolume?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Tank Material"}</label>
                <input
                  className={
                    errors.tankmaterial
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.tankMaterial}
                  name="tankmaterial"
                  id="tankmaterial"
                  {...register("tankmaterial")}
                />

                <span className="text-danger">
                  {errors.tankmaterial?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Thermal Conductivity"}</label>
                <input
                  className={
                    errors.tankthermalconductivity
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.tankThermalConductivity}
                  name="tankthermalconductivity"
                  id="tankthermalconductivity"
                  {...register("tankthermalconductivity")}
                />

                <span className="text-danger">
                  {errors.tankthermalconductivity?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Tank Thickness"}</label>
                <input
                  className={
                    errors.tanktheakness
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.tankTheakness}
                  name="tanktheakness"
                  id="tanktheakness"
                  {...register("tanktheakness")}
                />

                <span className="text-danger">
                  {errors.tanktheakness?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Tank Heating Medium"}</label>
                <input
                  className={
                    errors.tankheatingmedium
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.tankHeatingMedium}
                  name="tankheatingmedium"
                  id="tankheatingmedium"
                  {...register("tankheatingmedium")}
                />

                <span className="text-danger">
                  {errors.tankheatingmedium?.message}
                </span>
              </div>
            </div>
            <hr />

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"length (m)"}</label>
                <input
                  className={
                    errors.length
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.length}
                  name="length"
                  id="length"
                  {...register("length", {
                    onChange: (e) => {
                      handleChangeLength(e);
                    },
                  })}
                />
                <span className="text-danger">{errors.length?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Width (m)"}</label>
                <input
                  className={
                    errors.width
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.width}
                  name="width"
                  id="width"
                  {...register("width", {
                    onChange: (e) => {
                      handleChangeWidth(e);
                    },
                  })}
                />
                <span className="text-danger">{errors.width?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Height (m)"}</label>
                <input
                  className={
                    errors.height
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.height}
                  name="height"
                  id="height"
                  {...register("height", {
                    onChange: (e) => {
                      handleChangeHeight(e);
                    },
                  })}
                />
                <span className="text-danger">{errors.height?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Top Area (m2)"}</label>
                <input
                  className={
                    errors.toparea
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="toparea"
                  id="toparea"
                  defaultValue={props.data?.topArea}
                  readOnly
                  {...register("toparea")}
                />
                <span className="text-danger">{errors.toparea?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Bottom Area (m2)"}</label>
                <input
                  className={
                    errors.bottomarea
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="bottomarea"
                  id="bottomarea"
                  defaultValue={props.data?.bottomArea}
                  readOnly
                  {...register("bottomarea")}
                />
                <span className="text-danger">
                  {errors.bottomarea?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Aft Area (m2)"}</label>
                <input
                  className={
                    errors.aftarea
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="aftarea"
                  id="aftarea"
                  defaultValue={props.data?.afterArea}
                  readOnly
                  {...register("aftarea")}
                />
                <span className="text-danger">{errors.aftarea?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Forward Area (m2)"}</label>
                <input
                  className={
                    errors.forwardarea
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="forwardarea"
                  id="forwardarea"
                  defaultValue={props.data?.forwardArea}
                  readOnly
                  {...register("forwardarea")}
                />
                <span className="text-danger">
                  {errors.forwardarea?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Port Area (m2)"}</label>
                <input
                  className={
                    errors.portarea
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="portarea"
                  id="portarea"
                  defaultValue={props.data?.portArea}
                  readOnly
                  {...register("portarea")}
                />
                <span className="text-danger">{errors.portarea?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Starboard Area (m2)"}</label>
                <input
                  className={
                    errors.starboardarea
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="starboardarea"
                  id="starboardarea"
                  defaultValue={props.data?.starboardArea}
                  readOnly
                  {...register("starboardarea")}
                />
                <span className="text-danger">
                  {errors.starboardarea?.message}
                </span>
              </div>
            </div>
            <hr />

            <div className="col-12 col-md-8">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Top Area Case"}</label>
                <Controller
                  name="topareacase"
                  control={control}
                  defaultValue={
                    props.data !== undefined
                      ? [
                          {
                            name: props.data?.topAreaCase?.name,
                            identifier: props.data?.topAreaCase?.identifier,
                          },
                        ]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      options={areaCases !== undefined ? areaCases : []}
                      placeholder="Select case"
                      onChange={onChange}
                      // onBlur={onBlur}
                      defaultSelected={[
                        {
                          name: props.data?.topAreaCase?.name,
                          identifier: props.data?.topAreaCase?.identifier,
                        },
                      ]}
                      selected={value}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.topareacase?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Value"}</label>
                <input
                  className={
                    errors.topAreaCaseValue
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="topAreaCaseValue"
                  id="topAreaCaseValue"
                  defaultValue={props.data?.topAreaCase.value}
                  {...register("topAreaCaseValue")}
                />
                <span className="text-danger">
                  {errors.topAreaCaseValue?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-8">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Bottom Area Case"}</label>
                <Controller
                  name="bottomareacase"
                  control={control}
                  defaultValue={
                    props.data !== undefined
                      ? [
                          {
                            name: props.data?.bottomAreaCase?.name,
                            identifier: props.data?.bottomAreaCase?.identifier,
                          },
                        ]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      options={areaCases !== undefined ? areaCases : []}
                      placeholder="Select case"
                      onChange={onChange}
                      // onBlur={onBlur}
                      defaultSelected={[
                        {
                          name: props.data?.bottomAreaCase?.name,
                          identifier: props.data?.bottomAreaCase?.identifier,
                        },
                      ]}
                      selected={value}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.bottomareacase?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Value"}</label>
                <input
                  className={
                    errors.bottomAreaCaseValue
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="bottomAreaCaseValue"
                  id="bottomAreaCaseValue"
                  defaultValue={props.data?.bottomAreaCase.value}
                  {...register("bottomAreaCaseValue")}
                />
                <span className="text-danger">
                  {errors.bottomAreaCaseValue?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-8">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Aft Area Case"}</label>
                <Controller
                  name="afterareacase"
                  control={control}
                  defaultValue={
                    props.data !== undefined
                      ? [
                          {
                            name: props.data?.afterAreaCase?.name,
                            identifier: props.data?.afterAreaCase?.identifier,
                          },
                        ]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      options={areaCases !== undefined ? areaCases : []}
                      placeholder="Select case"
                      onChange={onChange}
                      // onBlur={onBlur}
                      defaultSelected={[
                        {
                          name: props.data?.afterAreaCase?.name,
                          identifier: props.data?.afterAreaCase?.identifier,
                        },
                      ]}
                      selected={value}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.afterareacase?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Value"}</label>
                <input
                  className={
                    errors.afterAreaCaseValue
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="afterAreaCaseValue"
                  id="afterAreaCaseValue"
                  defaultValue={props.data?.afterAreaCase.value}
                  {...register("afterAreaCaseValue")}
                />
                <span className="text-danger">
                  {errors.afterAreaCaseValue?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-8">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Forward Area Case"}</label>
                <Controller
                  name="forwardareacase"
                  control={control}
                  defaultValue={
                    props.data !== undefined
                      ? [
                          {
                            name: props.data?.forwardAreaCase?.name,
                            identifier: props.data?.forwardAreaCase?.identifier,
                          },
                        ]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      options={areaCases !== undefined ? areaCases : []}
                      placeholder="Select case"
                      onChange={onChange}
                      // onBlur={onBlur}
                      defaultSelected={[
                        {
                          name: props.data?.forwardAreaCase?.name,
                          identifier: props.data?.forwardAreaCase?.identifier,
                        },
                      ]}
                      selected={value}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.forwardareacase?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Value"}</label>
                <input
                  className={
                    errors.forwardAreaCaseValue
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="forwardAreaCaseValue"
                  id="forwardAreaCaseValue"
                  defaultValue={props.data?.forwardAreaCase.value}
                  {...register("forwardAreaCaseValue")}
                />
                <span className="text-danger">
                  {errors.forwardAreaCaseValue?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-8">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Port Area Case"}</label>
                <Controller
                  name="portareacase"
                  control={control}
                  defaultValue={
                    props.data !== undefined
                      ? [
                          {
                            name: props.data?.portAreaCase?.name,
                            identifier: props.data?.portAreaCase?.identifier,
                          },
                        ]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      options={areaCases !== undefined ? areaCases : []}
                      placeholder="Select case"
                      onChange={onChange}
                      // onBlur={onBlur}
                      defaultSelected={[
                        {
                          name: props.data?.portAreaCase?.name,
                          identifier: props.data?.portAreaCase?.identifier,
                        },
                      ]}
                      selected={value}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.portareacase?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Value"}</label>
                <input
                  className={
                    errors.portAreaCaseValue
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="portAreaCaseValue"
                  id="portAreaCaseValue"
                  defaultValue={props.data?.portAreaCase.value}
                  {...register("portAreaCaseValue")}
                />
                <span className="text-danger">
                  {errors.portAreaCaseValue?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-8">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Starboard Area Case"}</label>
                <Controller
                  name="starboardareacase"
                  control={control}
                  defaultValue={
                    props.data !== undefined
                      ? [
                          {
                            name: props.data?.starboardAreaCase?.name,
                            identifier:
                              props.data?.starboardAreaCase?.identifier,
                          },
                        ]
                      : []
                  }
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      options={areaCases !== undefined ? areaCases : []}
                      placeholder="Select case"
                      onChange={onChange}
                      // onBlur={onBlur}
                      defaultSelected={[
                        {
                          name: props.data?.starboardAreaCase?.name,
                          identifier: props.data?.starboardAreaCase?.identifier,
                        },
                      ]}
                      selected={value}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.starboardareacase?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Value"}</label>
                <input
                  className={
                    errors.starboardAreaCaseValue
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="starboardAreaCaseValue"
                  id="starboardAreaCaseValue"
                  defaultValue={props.data?.starboardAreaCase.value}
                  {...register("starboardAreaCaseValue")}
                />
                <span className="text-danger">
                  {errors.starboardAreaCaseValue?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setVisible(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary-blue"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            {loading && (
              <Spinner animation="border" variant="dark" role="status" />
            )}
            {" Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditTank;
