import { parse, format } from 'date-fns'
import { toast, ToastContainer } from 'react-toastify'

export const convertDateYYMMDD = (d, divider) => {
  var weekDate = new Date(d)
  let date = weekDate.getDate()
  let month = weekDate.getMonth() + 1
  const year = weekDate.getUTCFullYear()

  if (month < 10) {
    month = '0' + month
  }
  if (date < 10) {
    date = '0' + date
  }

  return year + divider + month + divider + date
}
export const convertDateYYMMDDUsingLib = (d) => {
  // Parse the date string into a Date object
  const parsedDate = parse(d, 'dd-MM-yyyy', new Date())

  // Format the Date object into the desired format
  const formattedDate = format(parsedDate, 'yyyy-MM-dd')

  // console.log(formattedDate); // Output: "2023-02-09"
  return formattedDate
}

export const convertDate = (d, divider) => {
  var weekDate = new Date(d)
  const date = weekDate.getDate()
  const month = weekDate.getMonth() + 1
  const year = weekDate.getUTCFullYear()

  return date + divider + month + divider + year
}

export const calMaxPage = (counts) => {
  const maxPage = Math.floor(counts / 10)

  if (counts % 10 === 0) {
    return maxPage
  } else {
    return maxPage + 1
  }
}

export function timeDuration(startTime, endTime) {
  const start = startTime.split(':').map(Number)
  const end = endTime.split(':').map(Number)
  const startInMinutes = start[0] * 60 + start[1]
  const endInMinutes = end[0] * 60 + end[1]
  const duration = endInMinutes - startInMinutes
  const hours = Math.floor(duration / 60)
  const minutes = duration % 60
  return `${hours}:${minutes < 10 ? '0' : ''}${minutes}`
}

export function timeDuration2(start, end) {
  const date1 = start
  const date2 = end

  const durationInMs = date2 - date1
  const durationInMinutes = durationInMs / (1000 * 60)
  const hours = Math.floor(durationInMinutes / 60)
  const minutes = durationInMinutes % 60
  const durationFormatted = `${hours.toString().padStart(2, '0')}:${minutes
    .toString()
    .padStart(2, '0')}`

  // console.log(durationFormatted); // Output: 01:30
  return durationFormatted
}

export async function downloadAsJSON(val) {
  const userInfo = JSON.parse(localStorage.getItem('userInfo'))

  console.log('val', val)
  val.customerId = userInfo.user_id

  if (Object.keys(val).length > 0) {
    const json = await JSON.stringify(val)
    // console.log('json', json)
    const blob = new Blob([json], { type: 'application/json' })
    const url = URL.createObjectURL(blob)
    const fileName = val.name + '_' + val.vesselname + '.json'
    const a = document.createElement('a')
    a.href = url
    a.download = fileName
    a.click()
    URL.revokeObjectURL(url)
  } else {
    return null
  }
}
