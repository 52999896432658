import React, { useState, useEffect, useRef, useReducer } from "react";
import { Trash2, ArrowLeftCircle } from "react-feather";
import { useForm, Controller } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Typeahead } from "react-bootstrap-typeahead";
import SweetAlert from "sweetalert2";
import { Modal, Pagination, Spinner } from "react-bootstrap";
import { calMaxPage } from "../../../common/Functions/CommonFunctions";
import EditTank from "./EditTank";
import TankInfo from "./TankInfo";
import { save_tank, get_tanks, delete_tank } from "../../../api";

const schema = yup.object().shape({
    tankname: yup.string().required("Tank name is required !"),
    tag: yup.string().required("Tag name is required !"),
    side: yup.string().required("Side is required !"),
    sequenceno: yup.string().required("Sequence no is required !"),
    totalvolume: yup
      .string()
      .required("Total volume is required !")
      .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
    tankmaterial: yup.string().required("Tank material is required !"),
    tankthermalconductivity: yup
      .string()
      .required("Tank thermal conductivity is required !")
      .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
      // .test("zero-or-less", "Tank thermal conductivity must be greater than 0.", function (value) {
      //   // 'value' contains the input value of tanktheakness
      //   return parseFloat(value) > 0;
      // }),
    tanktheakness: yup
      .string()
      .required("Tank thickness is required !")
      .matches(/^\d+(\.\d+)?$/, "No character input allowd")
      .test("zero-or-less", "Tank thickness must be greater than 0.", function (value) {
        // 'value' contains the input value of tanktheakness
        return parseFloat(value) > 0;
      })
      .test("maximum-thickness", "Tank thickness cannot above 0.015.", function (
        value
      ) {
        // 'value' contains the input value of tanktheakness
        return parseFloat(value) <= 0.015;
      }),
    tankheatingmedium: yup
      .string()
      .required("Tank heating medium is required !"),
    width: yup
      .string()
      .required("Width is required !")
      .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
    height: yup
      .string()
      .required("Heigth is required !")
      .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
    length: yup
      .string()
      .required("Length is required !")
      .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
    topareacase: yup
      .array()
      .required("Top area case is required")
      .min(1, "Select atleast one case"),
    topAreaCaseValue: yup
      .string()
      .required("Top area case value is required")
      .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
    bottomareacase: yup
      .array()
      .required("Bottom area case is required")
      .min(1, "Select atleast one case"),
    bottomAreaCaseValue: yup
      .string()
      .required("Bottom area case value is required")
      .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
    afterareacase: yup
      .array()
      .required("Aft area case is required")
      .min(1, "Select atleast one case"),
    afterAreaCaseValue: yup
      .string()
      .required("Aft area case value is required")
      .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
    forwardareacase: yup
      .array()
      .required("Forward area case is required")
      .min(1, "Select atleast one case"),
    forwardAreaCaseValue: yup
      .string()
      .required("Forward area case value is required")
      .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
    portareacase: yup
      .array()
      .required("Port area case is required")
      .min(1, "Select atleast one case"),
    portAreaCaseValue: yup
      .string()
      .required("Port area case value is required")
      .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
    starboardareacase: yup
      .array()
      .required("Starboard area case is required")
      .min(1, "Select atleast one case"),
    starboardAreaCaseValue: yup
      .string()
      .required("Starboard area case value is required")
      .matches(/^\d+(\.\d+)?$/, "No character input allowd"),
  })
  .required();

function TankMaster() {
  let navigate = useNavigate();
  let location = useLocation();

  const [tnk, setTnk] = useState([]);
  const [visible, setVisible] = useState(false);

  const [apiBySearch, setApiBySearch] = useState(false);
  const [dataForUpdation, setDataForUpdation] = useState();
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    control,
    formState,
    setValue,
    getValues,
    reset,
    watch,
  } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  let { errors } = formState;

  const selTopAreaCase = watch("topareacase");
  const selBottomAreaCase = watch("bottomareacase");
  const selAfterAreaCase = watch("afterareacase");
  const selForwardAreaCase = watch("forwardareacase");
  const selPortAreaCase = watch("portareacase");
  const selStarboardAreaCase = watch("starboardareacase");

  const areaCases = [
    {
      name: "Heat Transfer coefficient between tank top BHD & air",
      identifier: "TTBA",
    },
    {
      name: "Heat Transfer coefficient between tank side BHD & air",
      identifier: "TSBA",
    },
    {
      name: "Heat Transfer coefficient between tank bottom BHD & sea water",
      identifier: "TBSW",
    },
    { name: "Heat Transfer coefficient between tank & oil", 
      identifier: "TO" 
    },
    {
      name: "Heat Transfer coefficient between tank & void space fluid",
      identifier: "TVSF",
    },
    {
      name: "Heat Transfer coefficient between tank side BHD & sea water",
      identifier: "TSBSW",
    },
    {
      name: "Heat Transfer coefficient between tank & insulated wall fluid",
      identifier: "TIWF",
    }
  ];

  const initialState = {
    loading: true,
    error: "",
    tankList: [],
    limit: 10,
    offset: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "HANDLE_ACTION":
        return {
          offset: action.offset,
          limit: action.limit,
          loading: action.loading,
          error: action.error,
          tankList: action.tankList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log("location.state", location.state);
    console.log("state", state);
    dispatch({
      type: "HANDLE_ACTION",
      offset: state.offset,
      loading: true,
      error: state.error,
      tankList: state.tankList,
      limit: state.limit,
      maxPage: state.maxPage,
    });

    fetchTanks(location.state?.id);
  }, [state.offset, state.limit]);

  function fetchTanks(para1, para2) {
    dispatch({
      type: "HANDLE_ACTION",
      offset: para1 === "refresh" ? 0 : state.offset,
      loading: true,
      error: state.error,
      tankList: state.tankList,
      limit: state.limit,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      offset: state.offset-1,
      limit: state.limit,
      vesselid: location.state.id,
    };

    // if (para1 === 'search') {
    //   reqPayload = {
    //     offset: state.offset,
    //     limit: state.limit,
    //     searchVal: para2,
    //   }
    // }

    get_tanks(reqPayload).then(
      (res) => {
        console.log("ressd", res);
        console.log(
          "calMaxPage(res.data.count)",
          calMaxPage(res.data.data?.response?.count?.totalData)
        );
        setTotalRecords(res.data.data?.response?.count?.totalData);

        dispatch({
          type: "HANDLE_ACTION",
          offset: state.offset,
          loading: false,
          error: state.error,
          tankList: res.data.data.response.results,
          limit: state.limit,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.data?.response?.count?.totalData)
              : calMaxPage(res.data.data?.response?.count?.totalData),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", { autoClose: 2000 });
        }
      }
    );
  }

  const onSubmit = (data) => {
    console.log("data", data);
    if (data !== "") {
      setLoading(true);
      const payload = {
        vesselid: location.state?.id,
        // tankSelfId: "1",
        tankname: data.tankname, //"NO.1 C.O. TK (P)",
        tag: data.tag, //"NO.1 C.O. TK (P)",
        side: data.side, //"P",
        sequenceno: data.sequenceno, //1,
        totalvolume: data.totalvolume, //5763.7,
        tankmaterial: data.tankmaterial, //"Steel",
        tankthermalconductivity: data.tankthermalconductivity, // 50,
        tanktheakness: data.tanktheakness, //0.012,
        tankheatingmedium: data.tankheatingmedium, //"steam",
        width: data.width, //14.04,
        height: data.height, // 18.5,
        length: data.length, //27.2,
        toparea: data.toparea, //381.89,
        bottomarea: data.bottomarea, //381.89,
        afterarea: data.aftarea, //259.74,
        forwardarea: data.forwardarea, //259.74,
        portarea: data.portarea, //503.20,
        starboardarea: data.starboardarea, //503.20,
        topareacase: {
          name: selTopAreaCase[0].name,
          value: data.topAreaCaseValue,
          identifier: selTopAreaCase[0].identifier,
        },
        bottomareacase: {
          name: selBottomAreaCase[0].name,
          value: data.bottomAreaCaseValue,
          identifier: selBottomAreaCase[0].identifier,
        },
        afterareacase: {
          name: selAfterAreaCase[0].name,
          value: data.afterAreaCaseValue,
          identifier: selAfterAreaCase[0].identifier,
        },
        forwardareacase: {
          name: selForwardAreaCase[0].name,
          value: data.forwardAreaCaseValue,
          identifier: selForwardAreaCase[0].identifier,
        },
        portareacase: {
          name: selPortAreaCase[0].name,
          value: data.portAreaCaseValue,
          identifier: selPortAreaCase[0].identifier,
        },
        starboardareacase: {
          name: selStarboardAreaCase[0].name,
          value: data.starboardAreaCaseValue,
          identifier: selStarboardAreaCase[0].identifier,
        },
      };
      console.log("payload", payload);
      save_tank(payload)
        .then(
          (res) => {
            console.log("res", res);
            if (res.status === 200 || res.status === 201) {
              toast.success("Tank record created Successfully !", {
                autoClose: 3000,
              });
              fetchTanks(location.state?.id);
              setLoading(false);
              setVisible(false);
              reset();
            }
          },
          (err) => {
            console.log("err.response.status", err.response.status);
            if (err.response.status === 400) {
              console.log("err.response", err.response);
              toast.error("Tank record already exist ...", {
                autoClose: 3000,
              });
              setLoading(false);
              setVisible(false);
              reset();
            }
          }
        )
        .catch((error) => {
          console.log("error", error);
          toast.error("Something went wrong, please try again !", {
            autoClose: 3000,
          });
        });
    } else {
      toast.error("Something went wrong, please try again !", {
        autoClose: 1000,
      });
    }
  };

  // Deleting Tank
  const delTank = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure? \n" + val.tankName,
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        console.log(val.id);
        delete_tank({ id: val.id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Tank record deleted successfully !", {
              autoClose: 5000,
            });

            fetchTanks(location.state?.id);
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", { autoClose: 5000 });
          }
        );
      }
    });
  };

  var len, widt, higt;

  function handleChangeLength(e) {
    len = e.target.value;
    widt = getValues("width");
    higt = getValues("height");
    if (len !== "" && widt !== "" && higt !== "") {
      let res1 = parseFloat(len) * parseFloat(widt);
      let res2 = parseFloat(widt) * parseFloat(higt);
      let res3 = parseFloat(len) * parseFloat(higt);

      setValue("toparea", parseFloat(res1).toFixed(2));
      setValue("bottomarea", parseFloat(res1).toFixed(2));
      setValue("aftarea", parseFloat(res2).toFixed(2));
      setValue("forwardarea", parseFloat(res2).toFixed(2));
      setValue("portarea", parseFloat(res3).toFixed(2));
      setValue("starboardarea", parseFloat(res3).toFixed(2));
    }
  }

  function handleChangeWidth(e) {
    widt = e.target.value;
    len = getValues("length");
    higt = getValues("height");
    if (len !== "" && widt !== "" && higt !== "") {
      let res1 = parseFloat(len) * parseFloat(widt);
      let res2 = parseFloat(widt) * parseFloat(higt);
      let res3 = parseFloat(len) * parseFloat(higt);

      setValue("toparea", parseFloat(res1).toFixed(2));
      setValue("bottomarea", parseFloat(res1).toFixed(2));
      setValue("aftarea", parseFloat(res2).toFixed(2));
      setValue("forwardarea", parseFloat(res2).toFixed(2));
      setValue("portarea", parseFloat(res3).toFixed(2));
      setValue("starboardarea", parseFloat(res3).toFixed(2));
    }
  }

  function handleChangeHeight(e) {
    higt = e.target.value;
    widt = getValues("width");
    len = getValues("length");

    if (len !== "" && widt !== "" && higt !== "") {
      let res1 = parseFloat(len) * parseFloat(widt);
      let res2 = parseFloat(widt) * parseFloat(higt);
      let res3 = parseFloat(len) * parseFloat(higt);

      setValue("toparea", parseFloat(res1).toFixed(2));
      setValue("bottomarea", parseFloat(res1).toFixed(2));
      setValue("aftarea", parseFloat(res2).toFixed(2));
      setValue("forwardarea", parseFloat(res2).toFixed(2));
      setValue("portarea", parseFloat(res3).toFixed(2));
      setValue("starboardarea", parseFloat(res3).toFixed(2));
    }
  }

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <Modal show={visible} onHide={() => setVisible(false)} size={"lg"}>
        <Modal.Header className="bg-primary-blue" closeButton>
          <Modal.Title>{"Add Tank"}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">
            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Constant Name"}</label>
                <input
                  className={
                    errors.tankname
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter tank name"
                  name="tankname"
                  id="tankname"
                  {...register("tankname")}
                />

                <span className="text-danger">{errors.tankname?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Tag"}</label>
                <input
                  className={
                    errors.tag
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter tag name"
                  name="tag"
                  id="tag"
                  {...register("tag")}
                />

                <span className="text-danger">{errors.tag?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-2">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Side"}</label>
                <input
                  className={
                    errors.side
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter side"
                  name="side"
                  id="side"
                  {...register("side")}
                />

                <span className="text-danger">{errors.side?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-2">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Sequence No"}</label>
                <input
                  className={
                    errors.sequenceno
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter sequence no"
                  name="sequenceno"
                  id="sequenceno"
                  {...register("sequenceno")}
                />

                <span className="text-danger">
                  {errors.sequenceno?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Total Volume (m3)"}</label>
                <input
                  className={
                    errors.totalvolume
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter total volume"
                  name="totalvolume"
                  id="totalvolume"
                  {...register("totalvolume")}
                />

                <span className="text-danger">
                  {errors.totalvolume?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Tank Material"}</label>
                <input
                  className={
                    errors.tankmaterial
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter tank material"
                  name="tankmaterial"
                  id="tankmaterial"
                  {...register("tankmaterial")}
                />

                <span className="text-danger">
                  {errors.tankmaterial?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Thermal Conductivity"}</label>
                <input
                  className={
                    errors.tankthermalconductivity
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="tank thermal conductivity"
                  name="tankthermalconductivity"
                  id="tankthermalconductivity"
                  {...register("tankthermalconductivity")}
                />

                <span className="text-danger">
                  {errors.tankthermalconductivity?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Tank Thickness"}</label>
                <input
                  className={
                    errors.tanktheakness
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter tank thickness"
                  name="tanktheakness"
                  id="tanktheakness"
                  {...register("tanktheakness")}
                />

                <span className="text-danger">
                  {errors.tanktheakness?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Tank Heating Medium"}</label>
                <input
                  className={
                    errors.tankheatingmedium
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter tank heating medium"
                  name="tankheatingmedium"
                  id="tankheatingmedium"
                  {...register("tankheatingmedium")}
                />

                <span className="text-danger">
                  {errors.tankheatingmedium?.message}
                </span>
              </div>
            </div>
            <hr />

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"length (m)"}</label>
                <input
                  className={
                    errors.length
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter length"
                  name="length"
                  id="length"
                  {...register("length", {
                    onChange: (e) => {
                      handleChangeLength(e);
                    },
                  })}
                />
                <span className="text-danger">{errors.length?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Width (m)"}</label>
                <input
                  className={
                    errors.width
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter width"
                  name="width"
                  id="width"
                  {...register("width", {
                    onChange: (e) => {
                      handleChangeWidth(e);
                    },
                  })}
                />
                <span className="text-danger">{errors.width?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Height (m)"}</label>
                <input
                  className={
                    errors.height
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  placeholder="Enter height"
                  name="height"
                  id="height"
                  {...register("height", {
                    onChange: (e) => {
                      handleChangeHeight(e);
                    },
                  })}
                />
                <span className="text-danger">{errors.height?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Top Area (m2)"}</label>
                <input
                  className={
                    errors.toparea
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="toparea"
                  id="toparea"
                  readOnly
                  {...register("toparea")}
                />
                <span className="text-danger">{errors.toparea?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Bottom Area (m2)"}</label>
                <input
                  className={
                    errors.bottomarea
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="bottomarea"
                  id="bottomarea"
                  readOnly
                  {...register("bottomarea")}
                />
                <span className="text-danger">
                  {errors.bottomarea?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Aft Area (m2)"}</label>
                <input
                  className={
                    errors.aftarea
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="aftarea"
                  id="aftarea"
                  readOnly
                  {...register("aftarea")}
                />
                <span className="text-danger">{errors.aftarea?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Forward Area (m2)"}</label>
                <input
                  className={
                    errors.forwardarea
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="forwardarea"
                  id="forwardarea"
                  readOnly
                  {...register("forwardarea")}
                />
                <span className="text-danger">
                  {errors.forwardarea?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Port Area (m2)"}</label>
                <input
                  className={
                    errors.portarea
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="portarea"
                  id="portarea"
                  readOnly
                  {...register("portarea")}
                />
                <span className="text-danger">{errors.portarea?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Starboard Area (m2)"}</label>
                <input
                  className={
                    errors.starboardarea
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="starboardarea"
                  id="starboardarea"
                  readOnly
                  {...register("starboardarea")}
                />
                <span className="text-danger">
                  {errors.starboardarea?.message}
                </span>
              </div>
            </div>

            <hr />

            <div className="col-12 col-md-8">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Top Area Case"}</label>
                <Controller
                  name="topareacase"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      options={areaCases !== undefined ? areaCases : []}
                      placeholder="Select case"
                      onChange={onChange}
                      // onBlur={onBlur}
                      // selected={value}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.topareacase?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Value"}</label>
                <input
                  className={
                    errors.topAreaCaseValue
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="topAreaCaseValue"
                  id="topAreaCaseValue"
                  placeholder="Enter value"
                  {...register("topAreaCaseValue")}
                />
                <span className="text-danger">
                  {errors.topAreaCaseValue?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-8">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Bottom Area Case"}</label>
                <Controller
                  name="bottomareacase"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      options={areaCases !== undefined ? areaCases : []}
                      placeholder="Select case"
                      onChange={onChange}
                      // onBlur={onBlur}
                      // selected={value}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.bottomareacase?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Value"}</label>
                <input
                  className={
                    errors.bottomAreaCaseValue
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="bottomAreaCaseValue"
                  id="bottomAreaCaseValue"
                  placeholder="Enter value"
                  {...register("bottomAreaCaseValue")}
                />
                <span className="text-danger">
                  {errors.bottomAreaCaseValue?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-8">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Aft Area Case"}</label>
                <Controller
                  name="afterareacase"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      options={areaCases !== undefined ? areaCases : []}
                      placeholder="Select case"
                      onChange={onChange}
                      // onBlur={onBlur}
                      // selected={value}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.afterareacase?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Value"}</label>
                <input
                  className={
                    errors.afterAreaCaseValue
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="afterAreaCaseValue"
                  id="afterAreaCaseValue"
                  placeholder="Enter value"
                  {...register("afterAreaCaseValue")}
                />
                <span className="text-danger">
                  {errors.afterAreaCaseValue?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-8">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Forward Area Case"}</label>
                <Controller
                  name="forwardareacase"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      options={areaCases !== undefined ? areaCases : []}
                      placeholder="Select case"
                      onChange={onChange}
                      // onBlur={onBlur}
                      // selected={value}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.forwardareacase?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Value"}</label>
                <input
                  className={
                    errors.forwardAreaCaseValue
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="forwardAreaCaseValue"
                  id="forwardAreaCaseValue"
                  placeholder="Enter value"
                  {...register("forwardAreaCaseValue")}
                />
                <span className="text-danger">
                  {errors.forwardAreaCaseValue?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-8">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Port Area Case"}</label>
                <Controller
                  name="portareacase"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      options={areaCases !== undefined ? areaCases : []}
                      placeholder="Select case"
                      onChange={onChange}
                      // onBlur={onBlur}
                      // selected={value}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.portareacase?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Value"}</label>
                <input
                  className={
                    errors.portAreaCaseValue
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="portAreaCaseValue"
                  id="portAreaCaseValue"
                  placeholder="Enter value"
                  {...register("portAreaCaseValue")}
                />
                <span className="text-danger">
                  {errors.portAreaCaseValue?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-8">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Starboard Area Case"}</label>
                <Controller
                  name="starboardareacase"
                  control={control}
                  render={({ field: { onChange, onBlur, value } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="name"
                      multiple={false}
                      options={areaCases !== undefined ? areaCases : []}
                      placeholder="Select case"
                      onChange={onChange}
                      // onBlur={onBlur}
                      // selected={value}
                    />
                  )}
                />
                <span className="text-danger">
                  {errors.starboardareacase?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-4">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Value"}</label>
                <input
                  className={
                    errors.starboardAreaCaseValue
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  name="starboardAreaCaseValue"
                  id="starboardAreaCaseValue"
                  placeholder="Enter value"
                  {...register("starboardAreaCaseValue")}
                />
                <span className="text-danger">
                  {errors.starboardAreaCaseValue?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setVisible(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary-blue"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            {loading && (
              <Spinner animation="border" variant="dark" size="sm" />
            )}
            {" Save"}
          </button>
        </Modal.Footer>
      </Modal>

      <div className="py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card_title bg-primary-blue">
                  <h5 className="m-0">
                    <ArrowLeftCircle
                      className="me-2 cursor-pointer"
                      onClick={() => navigate(-1)}
                    />
                    {" Tank -"} {location.state?.vesselName}
                  </h5>
                </div>

                <div className="card-body">
                  {/* <CreateVessalMaster /> */}
                  <div className="d-flex justify-content-end col-md-12">
                    {/* <input
                      style={{ maxWidth: '300px' }}
                      className="form-control"
                      type="text"
                      name="search"
                      placeholder="Search"

                      // checking if search value is not empty then only calling the function
                      // onChange={(e) => {
                      //     if (e.target.value.length > 0) {
                      //         fetchTanks('search', e.target.value);
                      //         setApiBySearch(true);
                      //     } else {
                      //         fetchTanks();
                      //     }

                      // }}
                    /> */}
                    <button
                      className="btn bg-primary-blue "
                      type="submit"
                      onClick={() => setVisible(!visible)}
                    >
                      {"Add Tank"}
                    </button>
                  </div>

                  {/* {state.loading ? ( */}

                  {/* ) : state.tankList.length > 0 ? ( */}

                  <div className="table-responsive">
                    <table
                      className="table table-bordered mt-4 table-striped"
                      width={"100%"}
                    >
                      <thead className="thead-light">
                        <tr className="f-16 text-center">
                          <th scope="col">{"Sr"}</th>
                          <th scope="col">{"Name"}</th>
                          <th scope="col">{"Tag"}</th>
                          <th scope="col">{"Total Volume (m3)"}</th>
                          <th scope="col">{"Length (m)"}</th>
                          <th scope="col">{"Width (m)"}</th>
                          <th scope="col">{"Height (m)"}</th>
                          <th scope="col">{"Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.loading ? (
                          <Spinner
                            animation="border"
                            variant="dark"
                            role="status"
                          />
                        ) : (
                          state.tankList.map((val, i) => (
                            <tr key={i} className="f-16 text-center">
                              <td>{i + srNo + 1}</td>
                              {/* <td>{val.tankSelfId}</td> */}
                              <td>{val.tankName}</td>
                              <td>{val.tag}</td>
                              <td>{val.totalVolume}</td>
                              <td>{val.length}</td>
                              <td>{val.width}</td>
                              <td>{val.height}</td>
                              <td>
                                <EditTank fetchTanks={fetchTanks} data={val} />
                                <Trash2
                                  className="cursor-pointer"
                                  onClick={() => delTank(val)}
                                />
                                <TankInfo data={val} />
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>

                  {state.tankList.length == 0 && (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {"No Items Found !"}
                    </p>
                  )}

                  <div className="card-body p-0">
                    <div className="mt-4">
                      <Pagination
                        className="justify-content-end mb-0"
                        aria-label="Page navigation example"
                      >
                        <Pagination.Item
                          disabled={state.offset === 1 ? true : false}
                          className="cursor_pointer"
                          onClick={() => {
                            dispatch({
                              type: 'HANDLE_ACTION',
                              offset: state.offset - 1,
                              loading: state.loading,
                              error: state.error,
                              tankList: state.tankList,
                              limit: state.limit,
                              maxPage: state.maxPage,
                            })
                            setSrNo((prevC) => prevC - 10)
                          }}
                        >
                          {'Previous'}
                        </Pagination.Item>
                        <Pagination.Item active>{state.offset}</Pagination.Item>
                        <Pagination.Item
                          disabled={
                            state.offset === state.maxPage || state.maxPage === 0 ? true : false
                          }
                          className="cursor_pointer"
                          onClick={() => {
                            dispatch({
                              type: 'HANDLE_ACTION',
                              offset: state.offset + 1,
                              loading: state.loading,
                              error: state.error,
                              tankList: state.tankList,
                              limit: state.limit,
                              maxPage: state.maxPage,
                            })
                            setSrNo((prevC) => prevC + 10)
                          }}
                        >
                          {'Next'}
                        </Pagination.Item>
                      </Pagination>

                      <p className="f-14 text-muted float-start mt-1">{`Page: ${state.offset} out of ${state.maxPage}`}</p>
                      <p className="f-14 text-muted float-end mt-1">{`Total Records: ${totalRecords}`}</p>
                    </div>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default TankMaster;
