import React, { useState, useEffect, useRef } from 'react'
import { ArrowLeftCircle } from 'react-feather'
import { ToastContainer, toast } from 'react-toastify'
import { useNavigate } from 'react-router-dom'
import { Chart } from 'react-google-charts'
import { useDownloadExcel } from 'react-export-table-to-excel'
import html2canvas from 'html2canvas'
import { format } from 'date-fns'
import { PDFDownloadLink, PDFViewer, Document, Page, View, Text, Image } from '@react-pdf/renderer'

function OverallAnalysis() {
  let navigate = useNavigate()

  const [selectedFile, setSelectedFile] = useState(null);
  const [jsonData, setJsonData] = useState();

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  }

  // useEffect(() => {
  //   if (jsonData !== null && jsonData !== undefined && jsonData !== "") {
  //     return jsonData;
  //   }
  // }, [])

  
  

  const handleUpload = () => {
    // Use `selectedFile` to upload the file to the server
    // or process it in any way you want
    const reader = new FileReader();
    reader.readAsText(selectedFile);
    reader.onload = () => {
      const data = JSON.parse(reader.result);
      setJsonData(data);
    };
  }

  const [imageData, setImageData] = useState({ data: null })
  const [imageData2, setImageData2] = useState({ data: null })
  const [imageData3, setImageData3] = useState({ data: null })
  const [imageData4, setImageData4] = useState({ data: null })
  const [imageData5, setImageData5] = useState({ data: null })
  const [imageData6, setImageData6] = useState({ data: null })

  const tableRef = useRef(null)
  const chartRef = useRef(null)
  const chartRef2 = useRef(null)
  const chartRef3 = useRef(null)
  const chartRef4 = useRef(null)
  const chartRef5 = useRef(null)

  const { onDownload } = useDownloadExcel({
    currentTableRef: tableRef.current,
    filename: jsonData?.voyageName + '-Overall Analysis ' + format(new Date(), "dd-MM-yyyy"),
    sheet: 'OverallAnalysis',
  })

  const handleExport = () => {
    html2canvas(tableRef.current).then((canvas) => {
      imageData.data = canvas.toDataURL()
      setImageData({ ...imageData, data: imageData.data })
    })

    html2canvas(chartRef.current).then((canvas) => {
      imageData2.data = canvas.toDataURL()
      setImageData2({ ...imageData2, data: imageData2.data })
    })

    html2canvas(chartRef2.current).then((canvas) => {
      imageData3.data = canvas.toDataURL()
      setImageData3({ ...imageData3, data: imageData3.data })
    })

    html2canvas(chartRef3.current).then((canvas) => {
      imageData4.data = canvas.toDataURL()
      setImageData4({ ...imageData4, data: imageData4.data })
    })

    html2canvas(chartRef4.current).then((canvas) => {
      imageData5.data = canvas.toDataURL()
      setImageData5({ ...imageData5, data: imageData5.data })
    })

    html2canvas(chartRef5.current).then((canvas) => {
      imageData6.data = canvas.toDataURL()
      setImageData6({ ...imageData6, data: imageData6.data })
    })
  }

  return (
    <React.Fragment>
      <ToastContainer />
      <div className="py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-sm-12 col-xl-12">
              <div className="card">
                <div className="card_title bg-primary-blue pb-1 pt-1">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">
                      <ArrowLeftCircle className="me-2" onClick={() => navigate(-1)} />
                      {' Overall Analysis'}
                    </h5>
                    <button
                      className="btn f-w-600"
                      style={{ float: 'right', backgroundColor: 'white', color: '#2264ab' }}
                      // onClick={() => ExportToExcel('xlsx')}
                      onClick={onDownload}
                    >
                      {'Excel  '} <i className="ps-2 fa fa-download"></i>
                    </button>
                  </div>
                </div>
                <div className="card-body">
                  <div className="d-flex justify-content-start col-12 col-md-12">
                    <div className="col-md-6">
                      <input className='form-control' type="file" id="formFile" accept='.json' onChange={handleFileChange} />
                    </div>
                    <div className="col-md-3">
                      <button
                        className="btn bg-primary-blue ms-4"
                        onClick={handleUpload}
                      >
                        {'Upload'}
                      </button>
                    </div>
                    {/* <pre>{JSON.stringify(jsonData, null, 2)}</pre> */}
                  </div>
                  <div className="table-responsive pt-4" ref={tableRef}>
                    <table className="table table-bordered" id="tbl_steamConsumption">
                      <thead>
                        <tr>
                          <th>{'Voyage '}</th>
                          <td>{jsonData?.voyageName}</td>
                        </tr>
                        <tr>
                          <th>{'Voyage Start Point '}</th>
                          <td>{jsonData?.voyageStartPoint}</td>
                        </tr>
                        <tr>
                          <th>{'Voyage End Point '}</th>
                          <td>{jsonData?.voyageEndPoint}</td>
                        </tr>
                        <tr>
                          <th width={'20%'}>{'Legs'}</th>
                          {jsonData?.steamConsumpData.map((item, i) => (
                            // <th key={item._id} className="text-center">{item.legs}</th>
                            <th key={item._id} className="text-center">
                              {i + 1}
                            </th>
                          ))}
                        </tr>
                        <tr>
                          <th width={'20%'}>{'Voayge Start Date'}</th>
                          {jsonData?.steamConsumpData.map((item, i) => (
                            // <th key={item._id} className="text-center">{item.legs}</th>
                            <td key={item._id} className="text-center">
                              {item.departureDate}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <th width={'20%'}>{'Voayge Start Time'}</th>
                          {jsonData?.steamConsumpData.map((item, i) => (
                            // <th key={item._id} className="text-center">{item.legs}</th>
                            <td key={item._id} className="text-center">
                              {item.departureTime}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <th width={'20%'}>{'Voyage End Date'}</th>
                          {jsonData?.steamConsumpData.map((item, i) => (
                            // <th key={item._id} className="text-center">{item.legs}</th>
                            <td key={item._id} className="text-center">
                              {item.departureDate}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <th width={'25%'}>{'Voyage End Time'}</th>
                          {jsonData?.steamConsumpData.map((item, i) => (
                            // <th key={item._id} className="text-center">{item.legs}</th>
                            <td key={item._id} className="text-center">
                              {item.arrivalTime}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <th width={'25%'}>{'Steam Consumption (kg/h)'}</th>
                          {jsonData?.steamConsumpData.map((item, i) => (
                            // <th key={item._id} className="text-center">{item.legs}</th>
                            <td key={item._id} className="text-center">
                              {item.steamConsumption}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <th width={'25%'}>{'Boiler Loading (%)'}</th>
                          {jsonData?.boilerData.map((item, i) => (
                            // <th key={item._id} className="text-center">{item.legs}</th>
                            <td key={item._id} className="text-center">
                              {item.boilerLoading}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <th width={'25%'}>{'Boiler Efficiency (%)'}</th>
                          {jsonData?.boilerData.map((item, i) => (
                            // <th key={item._id} className="text-center">{item.legs}</th>
                            <td key={item._id} className="text-center">
                              {item.boilerEfficiency}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <th width={'25%'}>{'Fuel Oil Consumption (m3/h)'}</th>
                          {jsonData?.fuelConsumptionData.map((item, i) => (
                            // <th key={item._id} className="text-center">{item.legs}</th>
                            <td key={item._id} className="text-center">
                              {item.fuelConsumption}
                            </td>
                          ))}
                        </tr>
                        <tr>
                          <th width={'20%'}>{'Legs'}</th>
                          {jsonData?.steamConsumpData.map((item, i) => (
                            <th
                              scope="col"
                              colSpan={item.noOfDays.length}
                              key={item._id}
                              className="text-center"
                            >
                              {i + 1}
                            </th>
                          ))}
                        </tr>
                        <tr>
                          <th>{'Days'}</th>
                          {jsonData?.steamConsumpData.map((item, i) =>
                            item.noOfDays.map((num, numi) => (
                              <th key={numi} className="text-center">
                                {`Day ${num}`}
                              </th>
                            )),
                          )}
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th>{'Day Steam Consumption'}</th>
                          {jsonData?.steamConsumpData.map((item, i) =>
                            item.noOfDays.map((num, numi) => (
                              <td key={numi} className="text-center">
                                {item.steamConsumption}
                              </td>
                            )),
                          )}
                          {/* {leg.data.map((item, i) => (
                            <td key={item._id} className="text-center">{item.departure_port}</td>
                          ))} */}
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row m-t-5">
            <div className="col-sm-12 col-xl-12">
              <div className="card">
                <div className="card_title bg-primary-blue pb-1 pt-1">
                  <div className="d-flex justify-content-between align-items-center">
                    <h5 className="mb-0">{'Statistics'}</h5>
                    <div>
                      <button
                        className="btn f-w-600 ms-5"
                        style={{ float: 'right', backgroundColor: 'white', color: '#2264ab' }}
                        // onClick={() => ExportToExcel('xlsx')}
                        onClick={handleExport}
                      >
                        {'PDF  '} <i className="ps-2 fa fa-download"></i>
                      </button>
                      {imageData.data &&
                        imageData2.data &&
                        imageData3.data &&
                        imageData4.data &&
                        imageData5.data &&
                        imageData6.data && (
                          <PDFDownloadLink
                            style={{ color: 'white', lineHeight: '2.5rem' }}
                            document={
                              <Document>
                                <Page style={{ padding: '20px' }}>
                                  <View>
                                    <Image
                                      className="mt-5"
                                      src={imageData.data}
                                      style={{ height: '400px' }}
                                      alt="Screenshot"
                                    />
                                    <Image
                                      className="mt-5"
                                      src={imageData2.data}
                                      style={{ height: '300px' }}
                                      alt="Screenshot"
                                    />
                                    <Image
                                      className="mt-5"
                                      src={imageData3.data}
                                      style={{ height: '300px' }}
                                      alt="Screenshot"
                                    />
                                    <Image
                                      className="mt-5"
                                      src={imageData4.data}
                                      style={{ height: '300px' }}
                                      alt="Screenshot"
                                    />
                                    <Image
                                      className="mt-5"
                                      src={imageData5.data}
                                      style={{ height: '300px' }}
                                      alt="Screenshot"
                                    />
                                    <Image
                                      className="mt-5"
                                      src={imageData6.data}
                                      style={{ height: '300px' }}
                                      alt="Screenshot"
                                    />
                                  </View>
                                </Page>
                              </Document>
                            }
                            fileName= {jsonData?.voyageName + "-Overall_Analysis"+ format(new Date(), "dd-MM-yyyy") + ".pdf"}
                          >
                            {({ blob, url, loading, error }) =>
                              loading ? 'Loading document...' : 'Download now!'
                            }
                          </PDFDownloadLink>
                        )}
                    </div>
                  </div>
                </div>
                <div className="card-body" id="chart-container">
                  <div className="col-xl-12 mt-5" ref={chartRef}>
                    <div className="card mb-0 mt-0">
                      <div className="card_title">
                        <h5 className="mb-0">{'Leg Steam Consumption (kg/h)'}</h5>
                      </div>
                      <div className="card-body">
                        <Chart
                          width={'100%'}
                          height={'400px'}
                          chartType="LineChart"
                          loader={<div>{'Loading Chart'}</div>}
                          // data={[
                          //   ['x', 'Steam Consumption'],
                          //   [0, 0],
                          //   [10, 50],
                          //   [20, 34],
                          //   [30, 87],
                          //   [40, 11],
                          //   [50, 42],
                          //   [60, 9],
                          //   [70, 20],
                          // ]}
                          data={jsonData?.steamConsump_Graph }
                          options={{
                            hAxis: {
                              title: 'Legs',
                              minValue: 1,
                              ticks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                            },
                            vAxis: {
                              title: 'Steam Consumption (kg/h)',
                              minValue: 0,
                              ticks: [0, 50000, 100000, 150000],
                            },
                            colors: ['#4466f2'],
                            series: {
                              1: { curveType: 'function' },
                            },
                          }}
                          rootProps={{ 'data-testid': '2' }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-12 mt-5" ref={chartRef2}>
                    <div className="card mb-0 mt-0">
                      <div className="card_title">
                        <h5 className="mb-0">{'Boiler Loading (%)'}</h5>
                      </div>
                      <div className="card-body">
                        <Chart
                          width={'100%'}
                          height={'400px'}
                          chartType="LineChart"
                          loader={<div>{'Loading Chart'}</div>}
                          data={jsonData?.boilerLoading_Graph }
                          // data={[
                          //   ['x', 'Boiler Loading'],
                          //   [0, 59],
                          //   [1, 62],
                          //   [2, 60],
                          //   [3, 54],
                          //   [4, 57],
                          //   [5, 63],
                          //   [6, 65],
                          //   [7, 67],
                          // ]}
                          options={{
                            hAxis: {
                              title: 'Legs',
                              minValue: 1,
                              ticks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                            },
                            vAxis: {
                              title: 'Loading %',
                              minValue: 0,
                              ticks: [0, 50, 100, 150],
                            },
                            colors: ['#4466f2'],
                            series: {
                              1: { curveType: 'function' },
                            },
                          }}
                          rootProps={{ 'data-testid': '5' }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-12 mt-5" ref={chartRef3}>
                    <div className="card mb-0 mt-0">
                      <div className="card_title">
                        <h5 className="mb-0">{'Boiler Efficiency (%)'}</h5>
                      </div>
                      <div className="card-body">
                        <Chart
                          width={'100%'}
                          height={'400px'}
                          chartType="LineChart"
                          loader={<div>{'Loading Chart'}</div>}
                          data={jsonData?.boilerEfficiency_Graph }
                          // data={[
                          //   ['x', 'Boiler Efficiency'],
                          //   [0, 55],
                          //   [1, 50],
                          //   [2, 47],
                          //   [3, 41],
                          //   [4, 39],
                          //   [5, 43],
                          //   [6, 49],
                          //   [7, 51],
                          // ]}
                          options={{
                            hAxis: {
                              title: 'Legs',
                              minValue: 1,
                              ticks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                            },
                            vAxis: {
                              title: 'Efficiency %',
                              minValue: 0,
                              ticks: [0, 50, 100, 150],
                            },
                            colors: ['#4466f2'],
                            series: {
                              1: { curveType: 'function' },
                            },
                          }}
                          rootProps={{ 'data-testid': '2' }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-12 mt-5" ref={chartRef4}>
                    <div className="card mb-0 mt-0">
                      <div className="card_title">
                        <h5 className="mb-0">{'Fuel Oil Consumption (m3/h)'}</h5>
                      </div>
                      <div className="card-body">
                        <Chart
                          width={'100%'}
                          height={'400px'}
                          chartType="LineChart"
                          loader={<div>{'Loading Chart'}</div>}
                          data={jsonData?.fuelConsump_Graph }
                          // data={[
                          //   ["x", "Fuel Oil Consumption"],
                          //   [0, 59],
                          //   [1, 62],
                          //   [2, 60],
                          //   [3, 54],
                          //   [4, 57],
                          //   [5, 63],
                          //   [6, 65],
                          //   [7, 67],
                          // ]}
                          options={{
                            hAxis: {
                              title: 'Legs',
                              minValue: 1,
                              ticks: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
                            },
                            vAxis: {
                              title: 'Fuel Oil Consumption (m3/h)',
                              minValue: 0,
                              ticks: [0, 5000, 10000, 15000],
                            },
                            colors: ['#4466f2'],
                            series: {
                              1: { curveType: 'function' },
                            },
                          }}
                          rootProps={{ 'data-testid': '2' }}
                        />
                      </div>
                    </div>
                  </div>

                  <div className="col-xl-12 mt-5" ref={chartRef5}>
                    <div className="card mb-0 mt-0">
                      <div className="card_title">
                        <h5 className="mb-0">{'Day Steam Consumption (kg/h)'}</h5>
                      </div>
                      <div className="card-body">
                        <Chart
                          width={'100%'}
                          height={'400px'}
                          chartType="LineChart"
                          loader={<div>{'Loading Chart'}</div>}
                          data={jsonData?.daySteamConsump_Graph}
                          // data={[
                          //   ["x", "Fuel Oil Consumption"],
                          //   [0, 59],
                          //   [1, 62],
                          //   [2, 60],
                          //   [3, 54],
                          //   [4, 57],
                          //   [5, 63],
                          //   [6, 65],
                          //   [7, 67],
                          // ]}
                          options={{
                            hAxis: {
                              title: 'Day',
                              minValue: 1,
                              ticks: [1, 3, 5, 7, 9, 11, 13],
                            },
                            vAxis: {
                              title: 'Fuel Oil Consumption (m3/h)',
                              minValue: 0,
                              ticks: [0, 70000, 140000],
                            },
                            colors: ['#4466f2'],
                            series: {
                              1: { curveType: 'function' },
                            },
                          }}
                          rootProps={{ 'data-testid': '2' }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default OverallAnalysis
