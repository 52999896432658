import React from "react";
import Sidebar from "../Sidebar/Sidebar";
import NavbarComp from "../Navbar/NavbarComp";
import AppContent from "./AppContent";
import Footer from "../FooterSection/Footer"

function DefaultLayout() {
  return (
    <React.Fragment>
      <div className="min-vh-100" id="root_div">
        <Sidebar />
        <NavbarComp />
        <div id="root_body" style={{ marginLeft: "64px" }}>
          <AppContent />
        </div>
        <Footer />
      </div>
    </React.Fragment>
  );
}

export default DefaultLayout;
