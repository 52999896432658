import React from 'react'
import { yupResolver } from '@hookform/resolvers/yup'
import { toast, ToastContainer } from 'react-toastify'
import * as yup from 'yup'
import axios from 'axios'
import { useForm, Controller } from 'react-hook-form'
import { ArrowLeftCircle } from 'react-feather'
import { useNavigate } from 'react-router-dom'
import { forgetPassword } from '../../constants/index'

const schema = yup
  .object()
  .shape({
    username: yup.string().required('Username is required !'),
  })
  .required()

function ForgotPassword() {
  let navigate = useNavigate()
  const { register, handleSubmit, control, formState, reset, watch } = useForm({
    resolver: yupResolver(schema),
    // defaultValues: { username: 'techadmin@technicious.in', password: 'Tech@123' },
    mode: 'onChange',
  }) // initialise the hook

  let { errors } = formState

  const onSubmit = (data) => {
    console.log('data', data)
    data.applicationId = 'optiheat'
    const url = process.env.REACT_APP_API_URL + forgetPassword
    axios.post(url, data).then(
      (res) => {
        console.log('res', res)
        toast.success('Password sent on registered email successfully !', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
        reset()
        setTimeout(() => {
          navigate('/applogin')
        }, [2000])
      },
      (err) => {
        console.log('err', err)
        toast.error('Something went wrong!', {
          position: 'top-right',
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: 'light',
        })
      },
    )
  }

  return (
    <React.Fragment>
      <ToastContainer
        position="top-right"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />

      <div className="row mt-5" style={{ position: 'relative' }}>
        <div className="col-12 col-md-5 mx-auto my-auto ">
          <div className="card shadow-sm">
            <div className="card-header bg-primary-blue poppins-bold" style={{ fontSize: '20px' }}>
              <ArrowLeftCircle
                style={{ cursor: 'pointer' }}
                className=" me-2"
                onClick={() => {
                  navigate(-1)
                }}
              />{' '}
              Forgot password
            </div>
            <div className="card-body">
              <div className="row">
                <div className="col-12">
                  <div className="form-control border-0 p-0">
                    <label className="poppins-bold">Username</label>
                    <input
                      className="form-control"
                      {...register('username')}
                      type="text"
                      placeholder="Enter your username"
                    />
                    <span className="text-danger">{errors.username?.message}</span>
                  </div>
                  <div className="mt-3 d-flex justify-content-end">
                    <button className="btn btn-primary-blue w-100" onClick={handleSubmit(onSubmit)}>
                      Submit
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  )
}

export default ForgotPassword
