import React, { useState, useEffect, useRef, useReducer } from 'react'
import { Edit} from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import { useLocation, useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Modal, Spinner } from 'react-bootstrap'
import { calMaxPage } from '../../../common/Functions/CommonFunctions';
import { update_boiler } from '../../../api'

const schema = yup
  .object()
  .shape({
    boilername: yup.string().required("Boiler name is required !"),
    boilermodel: yup.string().required("Model name is required !"),
    steamgencapacity: yup
      .string()
      .required('Steam generation capacity is required !')
      .matches(/^\d+(\.\d+)?$/, 'No character input allowd'),
    steampressure: yup
      .string()
      .required('Steam pressure is required !')
      .matches(/^\d+(\.\d+)?$/, 'No character input allowd'),
    steamtemperature: yup
      .string()
      .required('Steam temperature is required !')
      .matches(/^\d+(\.\d+)?$/, 'No character input allowd'),
  })
  .required();

function EditBoiler(props) {

    let navigate = useNavigate();
    const [visible, setVisible] = useState(false);
    const [loading, setLoading]=useState(false);
    
    useEffect(() => {
      reset();
    },[visible])

    const { register, handleSubmit, control, formState, reset } = useForm({
        mode: 'onChange',
        resolver: yupResolver(schema),
      })

      let { errors } = formState;

      const onSubmit = (data) => {

        if(data !== ''){

          setLoading(true);
            const payload = {
              vesselid: props.vesselid,
              boilername: data.boilername,
              boilermodel: data.boilermodel,
              steamgencapacity: data.steamgencapacity,
              steampressure: data.steampressure,
              steamtemperature: data.steamtemperature,
            };

               console.log("data",payload,"id",props.data.id)
            update_boiler(payload, {id : props.data.id}).then(
              (res) => {
                console.log("res", res);
                if (res.status === 200 || res.status === 201) {

                  toast.success("Boiler record updated Successfully !", {
                    autoClose: 3000,
                  });
                  props.fetchBoilers({vesselid:props.vesselid});
                  setLoading(false)
                  setVisible(false);

                }
              },
              (err) => {
                console.log("err.response.status", err.response.status);
                if (err.response.status === 400) {
                  console.log("err.response", err.response);
                  toast.error("Boiler record already exist ...", {
                    autoClose: 3000,
                  });
                  setLoading(false)
                  setVisible(false);
                  reset();
                }
              }
            )
            .catch((error) => {
              console.log("error", error);
              toast.error("Something went wrong, please try again !", {
                autoClose: 3000,
              });
            });

        } else {
          toast.error("Something went wrong, please try again !", {
            autoClose: 1000,
          });
        }

      }

  return (
    <>
    <Edit
        className="cursor-pointer"
        onClick={() => {
            // setUpdateForm(val)
            setVisible(true)
        }}
    />
    {/* Edit Boiler Modal*/}
    <Modal show={visible} onHide={() => setVisible(false)} size={"lg"}>
        <Modal.Header className="bg-primary-blue" closeButton>
          <Modal.Title>Edit Boiler</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Boiler Name"}</label>
                <input
                  className={
                    errors.boilername
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.boilerName}
                  name="boilername"
                  {...register("boilername")}
                />

                <span className="text-danger">
                  {errors.boilername?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Model Detail Number"}</label>
                <input
                  className={
                    errors.boilermodel
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.boilerModel}
                  name="boilermodel"
                  {...register("boilermodel")}
                />

                <span className="text-danger">
                  {errors.boilermodel?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">
                  {"Steam Generation capacity"}
                  {"(Kg/h)"}
                </label>
                <input
                  className={
                    errors.steamgencapacity
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.steamGenCapacity}
                  name="steamgencapacity"
                  {...register("steamgencapacity")}
                />

                <span className="text-danger">
                  {errors.steamgencapacity?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">
                {"Steam Pressure"}
                {"(barg)"}
                </label>
                <input
                  className={
                    errors.steampressure
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.steamPressure}
                  name="steampressure"
                  {...register("steampressure")}
                />

                <span className="text-danger">
                  {errors.steampressure?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">
                {"Steam Temperature"}
                {"(°C)"}
                </label>
                <input
                  className={
                    errors.steamtemperature
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.steamTemperature}
                  name="steamtemperature"
                  {...register("steamtemperature")}
                />

                <span className="text-danger">
                  {errors.steamtemperature?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setVisible(false)}>
            Close
          </button>
          <button className="btn btn-primary-blue" disabled={loading} onClick={handleSubmit(onSubmit)} >
            {loading && <Spinner animation='border' variant="dark" size="sm" />}
            {' Save'}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default EditBoiler
