import React, { useEffect } from "react";

import VesselMaster from '../Project Components/Masters/Vessel/VesselMaster'
import TankMaster from '../Project Components/Masters/Tank/TankMaster'
import BoilerMaster from '../Project Components/Masters/Boiler/BoilerMaster'
import OverallAnalysis from '../Project Components/Report/OverallAnalysis'
import UserMaster from '../Project Components/UserMaster/VesselAdminMaster'
import ParameterMaster from '../Project Components/Masters/Parameter/Parameter'
import ParameterConstant from "../Project Components/Masters/PameterConstants/ParameterConstants";
import ResetPassword from "../Project Components/Authentication/ResetPassword";


const routes = [
    { path: "/optiheat/vesselMaster", name: "vesselMaster", element: VesselMaster },
    { path: '/optiheat/tankMaster', name: 'tankMaster', element: TankMaster },
    { path: '/optiheat/boilerMaster', name: 'boilerMaster', element: BoilerMaster },
    { path: '/optiheat/overallAnalysis', name: 'overallAnalysis', element: OverallAnalysis },
    { path: '/optiheat/userMaster', name: 'userMaster', element: UserMaster },
    { path: '/optiheat/parameterMaster', name: 'parameterMaster', element: ParameterMaster },
    { path: '/optiheat/constantMaster', name: 'constantMaster', element: ParameterConstant },
    { path: '/optiheat/resetPassword', name: 'resetPassword', element: ResetPassword },
];

export default routes;
