import React, { useState, useEffect, useRef, useReducer } from 'react'
import { Edit } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Typeahead } from 'react-bootstrap-typeahead'
import { Modal, Spinner } from 'react-bootstrap'
import { calMaxPage } from '../../../common/Functions/CommonFunctions'
import { update_parameter } from '../../../api'

const schema = yup
  .object()
  .shape({
    sequenceno: yup.string().required('Sequence number is required!').matches(/^\d+$/, 'Sequence number must be a number.'),
    name: yup.string().required('Parameter name is required !'),
    unit: yup.string().required('Unit is required'),
    type: yup.array().min(1, 'Select one type').required('Type is required !'),
  })
  .required()

function EditParameter(props) {
  let navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const { register, handleSubmit, control, formState, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  let { errors } = formState

  const prmList = [
    { id: 1, name: 'weather', label: 'Weather' },
    { id: 2, name: 'calculation', lable: 'Calculation' },
  ]

  useEffect(() => {
    if (visible) {
      reset()
    }
  }, [visible])

  const onSubmit = (data) => {
    console.log('data', data)
    if (data !== '') {
      setLoading(true)
      const payload = {
        customerid: props.data?.customerId,
         // vessel_admin: props.data?.vessel_admin,
        sequenceno: parseInt(data.sequenceno),
        name: data.name,
        unit: data.unit,
        type: data.type[0]?.name,
      }

      console.log('payload', payload, 'id', props.data.id)

      update_parameter(payload, {id : props.data?.id}).then(
          (res) => {
            console.log('res', res)
            if (res.status === 200 || res.status === 201) {
              toast.success('Parameter record updated Successfully !', {
                autoClose: 2000,
              })
              props.fetchParameters()
              setLoading(false)
              setVisible(false)
              reset()
            }
          },
          (err) => {
            console.log('err.response', err.response)
            if (err.response.status === 400) {
              console.log('err.response', err.response)
              toast.error('Parameter record already exist ...', {
                autoClose: 2000,
              })
              // setVisible(false)
              // setLoading(false)
              // reset()
            }
          },
        )
    } else {
      toast.error('Something went wrong, please try again !', {
        autoClose: 2000,
      })
    }
  }

  return (
    <>
      <Edit
        height="18"
        width="18"
        className="cursor-pointer me-1"
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true)
        }}
      />
      {/* Edit Parameter Modal*/}
      <Modal show={visible} onHide={() => setVisible(false)} size={'lg'}>
        <Modal.Header className="bg-primary-blue" closeButton>
          <Modal.Title>Edit Parameter</Modal.Title>
        </Modal.Header>
        <Modal.Body>
        <form className="row g-3">
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Sequence No"}</label>
                <input
                  className={
                    errors.sequenceno
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  readOnly
                  defaultValue={props.data?.sequenceNo}
                  name="sequenceno"
                  {...register("sequenceno")}
                />

                <span className="text-danger">
                  {errors.sequenceno?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Parameter Name"}</label>
                <input
                  className={
                    errors.name
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.name}
                  name="name"
                  {...register("name")}
                />

                <span className="text-danger">
                  {errors.name?.message}
                </span>
              </div>
            </div>
            
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Unit"}</label>
                <input
                  className={
                    errors.unit
                      ? "form-control is-invalid f-16"
                      : " f-16 form-control"
                  }
                  type="text"
                  defaultValue={props.data?.unit}
                  name="unit"
                  {...register("unit")}
                />

                <span className="text-danger">
                  {errors.unit?.message}
                </span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Type"}</label>
                <Controller
                name="type"
                control={control}
                defaultValue={[{name: props.data?.type}]}
                render={({ field: { onChange, onBlur, value } }) => (
                  <Typeahead
                    defaultSelected={[{name: props.data?.type}]}
                    id="basic-typeahead-single"
                    labelKey="name"
                    multiple={false}
                    // options={cnt !== undefined || cnt !== '' ? cnt : []}
                    options={prmList !== undefined ? prmList : []}
                    placeholder="Select Type"
                    onChange={onChange}
                    // onBlur={onBlur}
                    // selected={value}
                  />
                )}
              />
                <span className="text-danger">
                  {errors.type?.message}
                </span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setVisible(false)}>
            Close
          </button>
          <button className="btn btn-primary-blue" onClick={handleSubmit(onSubmit)}>
            {loading && <Spinner animation='border' variant="dark" size="sm" />}
            {' Save'}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  )
}
export default EditParameter
