import React, { useState, useEffect, useRef, useReducer } from 'react'
import { Edit } from 'react-feather'
import { useForm, Controller } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { ToastContainer, toast } from 'react-toastify'
import { yupResolver } from '@hookform/resolvers/yup'
import * as yup from 'yup'
import { Modal, Spinner } from 'react-bootstrap'
import { calMaxPage } from '../../../common/Functions/CommonFunctions'
import { update_vessel } from '../../../api'

const schema = yup
  .object()
  .shape({
    vesselname: yup.string().required('Vessel name is required !'),
    uniqueno: yup.string().required('Unique number is required !'),
    imonumber: yup.string().required('IMO number is required !'),
    gt: yup.string().required('GT is required !'),
  })
  .required()

function EditVessel(props) {
  let navigate = useNavigate()
  const [visible, setVisible] = useState(false)
  const [loading, setLoading] = useState(false)

  const { register, handleSubmit, control, formState, reset } = useForm({
    mode: 'onChange',
    resolver: yupResolver(schema),
  })

  let { errors } = formState

  useEffect(() => {
    if (visible) {
      reset()
    }
  }, [visible])

  const onSubmit = (data) => {
    console.log('data', data)
    if (data !== '') {
      setLoading(true)
      const payload = {
        vesselname: data.vesselname,
        uniqueno: data.uniqueno,
        imonumber: data.imonumber,
        gt: data.gt,
      }

      console.log('payload', payload, 'id', props.data.id)
      update_vessel(payload, props.data.id)
        .then(
          (res) => {
            console.log('res', res)
            if (res.status === 200 || res.status === 201) {
              toast.success('Vessel record updated Successfully !', {
                autoClose: 3000,
              })
              props.fetchVessels()
              setLoading(false)
              setVisible(false)


              reset()
            }
          },
          (err) => {
            console.log('err.response.status', err.response.status)
            if (err.response.status === 400) {
              console.log('err.response', err.response)
              toast.error('Vessel record already exist ...', {
                autoClose: 3000,
              })
              setLoading(false)
              setVisible(false)
            }
          },
        )
        .catch((error) => {
          console.log('error', error)
          toast.error('Something went wrong, please try again !', {
            autoClose: 3000,
          })
        })
    } else {
      toast.error('Something went wrong, please try again !', {
        autoClose: 1000,
      })
    }
  }

  return (
    <>
      <Edit
      height="18" width="18"
        className="cursor-pointer me-1"
        onClick={() => {
          // setUpdateForm(val)
          setVisible(true)
        }}
      />
      {/* Edit Item Modal*/}

      <Modal show={visible} onHide={() => setVisible(false)} size="lg">
        <Modal.Header className="bg-primary-blue" closeButton>
          <Modal.Title>Edit Vessel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">
            <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{"Vessel Name"}</label>
                  <input
                    className={
                      errors.vesselname
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    defaultValue={props.data?.vesselName}
                    name="vesselname"
                    {...register("vesselname")}
                  />

                  <span className="text-danger">
                    {errors.vesselname?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{"Unique Number"}</label>
                  <input
                    className={
                      errors.uniqueno
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    defaultValue={props.data?.uniqueNo}
                    name="uniqueno"
                    {...register("uniqueno")}
                  />

                  <span className="text-danger">
                    {errors.uniqueno?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{"IMO Number"}</label>
                  <input
                    className={
                      errors.imonumber
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    defaultValue={props.data?.imoNumber}
                    name="imonumber"
                    {...register("imonumber")}
                  />

                  <span className="text-danger">
                    {errors.imonumber?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{"GT"}</label>
                  <input
                    className={
                      errors.gt
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    defaultValue={props.data?.gt}
                    name="gt"
                    {...register("gt")}
                  />

                  <span className="text-danger">{errors.gt?.message}</span>
                </div>
              </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setVisible(false)}>
            Close
          </button>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
          >
            {loading && <Spinner animation='border' variant="dark" size="sm" />}

            {" Save"}
          </button>
        </Modal.Footer>
      </Modal>

    </>
  )
}

export default EditVessel
