import React, { useState, useEffect } from "react";
import { Edit, Trash2, Eye, EyeOff, Download } from "react-feather";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";
import { ToastContainer, toast } from "react-toastify";
import { Typeahead } from "react-bootstrap-typeahead";
import { get_vessels, update_vessel_admin } from "../../api";
import { Modal, Spinner } from "react-bootstrap";

const schema = yup
  .object()
  .shape({
    name: yup.string().required("Name is required !"),
    email: yup.string().required("Email is required !"),
    username: yup.string().required("Username is required"),
    vessel: yup.array().required("Please select vessel !"),
    rank: yup.string().required("Rank is required !"),
  })
  .required();

function EditVesselAdmin(props) {
  let navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [sameAsEmail, setSameAsEmail] = useState(false);
  const [vesselList, setVesselList] = useState({ data: [], state: false });
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState,
    control,
    watch,
    setValue,
    getValues,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
  });
  let { errors } = formState;

  useEffect(() => {
    console.log("sameAsEmail", sameAsEmail);
    if (sameAsEmail) {
      const email = getValues("email");
      setValue("username", email);
    }
  }, [sameAsEmail]);

  useEffect(() => {
    if (visible == true) {
      reset();
    }
  }, [visible]);

  useEffect(() => {
    fetchVessels();
  }, []);

  function fetchVessels() {
    setVesselList({ ...vesselList, data: [], state: true });
    const payload = {
      offset: 0,
      limit: 10,
    };

    get_vessels(payload).then(
      (res) => {
        console.log("res", res);
        // const data = res.data.data
        // console.log('data', data)
        // if (res.data.statusCode == 200) {
        setVesselList({
          ...vesselList,
          data: res.data.data?.response?.results,
          state: false,
        });
        // }
      },
      (err) => {
        console.log("err", err);
      }
    );
  }

  const onSubmit = (data) => {
    console.log("data", data);

    if (data !== "") {
      setLoading(true);
      let payload = {
        name: data.name,
        email: data.email,
        username: data.username,
        // password:data.password ,
        website: "",
        address: "",
        contactnumber: "",
        customertype: "",
        country: "",
        state: "",
        city: "",
        signature: "",
        rank: data.rank,
        vesselname: data.vessel[0].vesselName,
        vesselid: data.vessel[0].id,
      };

      // if(payload.vessel==undefined){
      //   payload.vesselname=props.data.vesselname;
      //   payload.vesselid=props.data.vesselid
      // }
      // else{
      //   payload.vesselname=data.vessel.vesselname;
      //   payload.vesselid=data.vessel[0].vesselid
      // }

      console.log("payasdnf", payload);

      update_vessel_admin(payload, { id: props.data.id }).then(
        (res) => {
          console.log("res", res);
          toast.success("Vessel admin updated successfully", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
          props.fetchvesseladmins();
          setLoading(false);
          setVisible(false);
          reset();
        },
        (err) => {
          console.log("err", err);
          setLoading(false);
          toast.error("Something went wrong", {
            position: "top-right",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "light",
          });
        }
      );
    } else {
      errors.showMessages();
    }
  };

  return (
    <>
      <Edit
        className="cursor-pointer me-2"
        onClick={() => {
          setVisible(true);
        }}
      />
      <Modal
       show={visible} onHide={() => setVisible(false)} size="lg"
      >
        <Modal.Header className="bg-primary-blue" closeButton>
          <Modal.Title className="">Update Vessel Admin</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">
            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Name"}</label>
                <input
                  className={
                    errors.name ? "form-control is-invalid" : "form-control"
                  }
                  type="text"
                  placeholder="Name*"
                  name="name"
                  {...register("name")}
                  defaultValue={props.data.name}
                />

                <span className="text-danger">{errors.name?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Rank"}</label>
                <input
                  className={
                    errors.rank ? "form-control is-invalid" : "form-control"
                  }
                  type="text"
                  placeholder="Rank*"
                  name="rank"
                  {...register("rank")}
                  defaultValue={props.data.rank}
                />

                <span className="text-danger">{errors.rank?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Email"}</label>
                <input
                  className={
                    errors.email ? "form-control is-invalid" : "form-control"
                  }
                  type="email"
                  name="email"
                  placeholder="Email Address*"
                  {...register("email")}
                  defaultValue={props.data.email}
                />

                <span className="text-danger">{errors.email?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Username"}</label>
                <input
                  className={
                    errors.username ? "form-control is-invalid" : "form-control"
                  }
                  type="text"
                  name="username"
                  placeholder="Username*"
                  {...register("username")}
                  defaultValue={props.data.username}
                />
                <div className="form-group form-check">
                  <input
                    type="checkbox"
                    checked={sameAsEmail}
                    onChange={(e) => setSameAsEmail(e.target.checked)}
                    className="form-check-input"
                    id="exampleCheck1"
                  />
                  <label
                    style={{ fontSize: "13px", color: "gray" }}
                    className="form-check-label"
                    htmlFor="exampleCheck1"
                  >
                    Same As Email
                  </label>
                </div>
                <span className="text-danger">{errors.username?.message}</span>
              </div>
            </div>

            <div className="col-12 col-md-6">
              <div className="form-group mb-2">
                <label className="poppins-bold">{"Select Vessel"}</label>
                <Controller
                  name="vessel"
                  control={control}
                  defaultValue={[{ vesselName: props.data.vesselname }]}
                  render={({ field: { onChange, onBlur, value, ref } }) => (
                    <Typeahead
                      id="basic-typeahead-single"
                      labelKey="vesselName"
                      multiple={false}
                      options={vesselList.data}
                      isLoading={vesselList.state}
                      //   isLoading={locations.state}
                      placeholder="Select Vessel"
                      onChange={onChange}
                      onBlur={onBlur}
                      defaultSelected={[{ vesselName: props.data.vesselname }]}
                      selected={value}
                      isInvalid={errors.vessel ? true : false}
                    />
                  )}
                />
                <span className="text-danger">{errors.vessel?.message}</span>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button
            className="btn btn-secondary"
            onClick={() => setVisible(false)}
          >
            Close
          </button>
          <button
            className="btn btn-primary-blue"
            disabled={loading}
            onClick={handleSubmit(onSubmit)}
          >
            {loading && <Spinner animation='border' variant="dark" size="sm" />}
            {" Save"}
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default EditVesselAdmin;
