import React, { useState, useEffect, useRef, useReducer } from "react";
import { Edit, Trash2, ArrowLeftCircle, Download } from "react-feather";
import { useForm, Controller } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { Modal, Dropdown, Spinner, Pagination } from "react-bootstrap";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import SweetAlert from "sweetalert2";
import { calMaxPage } from "../../../common/Functions/CommonFunctions";
import EditVessel from "./EditVessel";
import {
  save_vessel,
  get_vessels,
  delete_vessel,
  get_tanks,
  get_boilers,
  get_parameters,
  get_ParameterConstants,
} from "../../../api";

const schema = yup
  .object()
  .shape({
    vesselname: yup.string().required("Vessel name is required !"),
    uniqueno: yup.string().required("Unique number is required !"),
    imonumber: yup.string().required("IMO number is required !"),
    gt: yup.string().required("GT is required !"),
  })
  .required();

function VesselMaster() {
  let navigate = useNavigate();
  const [ves, setVes] = useState([]);
  const [visible, setVisible] = useState(false);

  const [apiBySearch, setApiBySearch] = useState(false);
  const [dataForUpdation, setDataForUpdation] = useState();
  const [srNo, setSrNo] = useState(0);
  const [totalRecords, setTotalRecords] = useState("");
  const [loading, setLoading] = useState(false);

  const userInfo = JSON.parse(localStorage.getItem("userInfo"));

  const { register, handleSubmit, control, formState, reset } = useForm({
    mode: "onChange",
    resolver: yupResolver(schema),
  });

  let { errors } = formState;

  const initialState = {
    loading: true,
    error: "",
    vesselList: [],
    limit: 10,
    offset: 1,
    maxPage: 0,
  };

  const reducer = (state, action) => {
    switch (action.type) {
      case "HANDLE_ACTION":
        return {
          offset: action.offset,
          limit: action.limit,
          loading: action.loading,
          error: action.error,
          vesselList: action.vesselList,
          maxPage: action.maxPage,
        };

      default:
        return state;
    }
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    console.log("userInfo", userInfo);
    console.log("state", state);
    dispatch({
      type: "HANDLE_ACTION",
      offset: state.offset,
      loading: true,
      error: state.error,
      vesselList: state.vesselList,
      limit: state.limit,
      maxPage: state.maxPage,
    });

    fetchVessels();
  }, [state.offset, state.limit]);

  function fetchVessels(para1, para2) {
    dispatch({
      type: "HANDLE_ACTION",
      offset: para1 === "refresh" ? 1 : state.offset,
      loading: true,
      error: state.error,
      vesselList: state.vesselList,
      limit: state.limit,
      maxPage: state.maxPage,
    });

    var reqPayload = {
      offset: state.offset - 1,
      limit: state.limit,
    };

    if (para1 === "search") {
      reqPayload = {
        // offset: state.offset,
        // limit: state.limit,
        search: para2,
      };
    }

    get_vessels(reqPayload).then(
      (res) => {
        console.log("res", res.data.data.response.results);
        console.log(
          "calMaxPage(res.data.count)",
          calMaxPage(res.data.data.response.count.totalData)
        );
        setTotalRecords(res.data.data.response.count.totalData);

        dispatch({
          type: "HANDLE_ACTION",
          offset: state.offset,
          loading: false,
          error: state.error,
          vesselList: res.data.data.response.results,
          limit: state.limit,
          maxPage:
            para1 === "search"
              ? calMaxPage(res.data.data.response.count.totalData)
              : calMaxPage(res.data.data.response.count.totalData),
          // Checking if user is searching customer, using above logic for pagination
        });
      },
      (err) => {
        console.log("err", err);

        if (err.response.status === 500) {
          toast.error("Something went wrong !", { autoClose: 2000 });
        }
      }
    );
  }

  const onSubmit = (data) => {
    console.log("data", data);
    if (data !== "") {
      setLoading(true);
      const payload = {
        vesselname: data.vesselname,
        uniqueno: data.uniqueno,
        imonumber: data.imonumber,
        gt: data.gt,
      };

      save_vessel(payload)
        .then(
          (res) => {
            console.log("res", res);
            if (res.status === 200 || res.status === 201) {
              toast.success("Vessel record created Successfully !", {
                autoClose: 3000,
              });
              fetchVessels();
              setLoading(false);
              setVisible(false);
              reset();
            }
          },
          (err) => {
            console.log("err.response.status", err.response.status);
            if (err.response.status === 400) {
              console.log("err.response", err.response);
              toast.error(err.response.data.data.response, {
                autoClose: 2000,
              });
              setVisible(false);
              setLoading(false);
              reset();
            }
          }
        )
    } else {
      toast.error("Something went wrong, please try again !", {
        autoClose: 2000,
      });
    }
  };

  // Deleting Vessel
  const delVessel = (val) => {
    console.log("val", val);
    SweetAlert.fire({
      title: "Are you sure? \n" + val.vesselName,
      text: "Once deleted, you will not be able to recover this record !",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Ok",
      cancelButtonText: "cancel",
      reverseButtons: true,
    }).then((result) => {
      if (result.value) {
        // Firing delete api
        delete_vessel({ id: val.id }).then(
          (res) => {
            console.log("res", res);
            toast.success("Vessel record deleted successfully !", {
              autoClose: 2000,
            });
            fetchVessels();
          },
          (err) => {
            console.log("err", err);
            toast.error("Something went wrong !", { autoClose: 5000 });
          }
        );
      }
    });
  };

  const downloadJson = async (val) => {
    var resTanks = [];
    var resBoilers = [];
    var resBoilerSpec = [];
    var resParameter = [];
    var resConstant = [];
    var totTank = 0;
    var totBoiler = 0;

    var reqPayload = {
      offset: 0,
      limit: 1000,
      vesselid: val.id
    };

    if (val !== "" || val !== undefined) {
      await get_tanks(reqPayload).then(
        (res) => {
          console.log('Tank result', res);

          resTanks = [...res.data.data.response.results];

          for (let i = 0; i < resTanks.length; i++) {
            resTanks[i].tankSelfId = resTanks[i].id;
            resTanks[i].width = Number(resTanks[i].width)
            resTanks[i].height = Number(resTanks[i].height)
            resTanks[i].length = Number(resTanks[i].length)
            resTanks[i].sequenceNo = Number(resTanks[i].sequenceNo)
            resTanks[i].totalVolume = Number(resTanks[i].totalVolume)
            resTanks[i].tankTheakness = Number(resTanks[i].tankTheakness)
            resTanks[i].tankThermalConductivity = Number(resTanks[i].tankThermalConductivity)

            resTanks[i].topArea = Number(resTanks[i].topArea)
            resTanks[i].bottomArea = Number(resTanks[i].bottomArea)
            resTanks[i].afterArea = Number(resTanks[i].afterArea)
            resTanks[i].forwardArea = Number(resTanks[i].forwardArea)
            resTanks[i].portArea = Number(resTanks[i].portArea)
            resTanks[i].starboardArea = Number(resTanks[i].starboardArea)

            resTanks[i].topAreaCase.value = Number(resTanks[i].topAreaCase.value)
            resTanks[i].bottomAreaCase.value = Number(resTanks[i].bottomAreaCase.value)
            resTanks[i].afterAreaCase.value = Number(resTanks[i].afterAreaCase.value)
            resTanks[i].forwardAreaCase.value = Number(resTanks[i].forwardAreaCase.value)
            resTanks[i].portAreaCase.value = Number(resTanks[i].portAreaCase.value)
            resTanks[i].starboardAreaCase.value = Number(resTanks[i].starboardAreaCase.value)
            
          }

          totTank = res.data.data.response.results.length;
        },
        (err) => {
          console.log("err", err);
          if (err.response.status === 500) {
            toast.error("Something went wrong !", { autoClose: 2000 });
          }
        }
      );

      await get_boilers(reqPayload).then(
        (res) => {
          // console.log('Boiler result', res);

          resBoilers = [...res.data.data.response.results];
          // console.log('resBoilers', resBoilers);
          for (let i = 0; i < resBoilers.length; i++) {
            resBoilers[i].boilerSelfId = resBoilers[i].id;
          }

          totBoiler = res.data.data.response.results.length;
        },
        (err) => {
          console.log("err", err);
          if (err.response.status === 500) {
            toast.error("Something went wrong !", { autoClose: 2000 });
          }
        }
      );

      await get_parameters(reqPayload).then(
        (res) => {
          // console.log('Parameter result', res);
          resParameter = [...res.data.data.response.results];

          for (let i = 0; i < resParameter.length; i++) {
            resParameter[i].sequenceNo = Number(resParameter[i].sequenceNo);
          }
        },
        (err) => {
          console.log("err", err);
          if (err.response.status === 500) {
            toast.error("Something went wrong !", { autoClose: 2000 });
          }
        }
      );

      await get_ParameterConstants(reqPayload).then(
        (res) => {
          // console.log('Parameter result', res);
          resConstant = [...res.data.data.response.results];

          for (let i = 0; i < resConstant.length; i++) {
            resConstant[i].value = Number(resConstant[i].value);
          }
        },
        (err) => {
          console.log("err", err);
          if (err.response.status === 500) {
            toast.error("Something went wrong !", { autoClose: 2000 });
          }
        }
      );

      console.log("resTanks", resTanks);

      const payload = {
        vesselSelfId: val.id,
        vesselName: val.vesselName,
        IMONumber: val.imoNumber,
        uniqueNo: val.uniqueNo,
        GT: val.gt,
        webUserId: val.user_id,
        tankData: resTanks,
        boilerData: resBoilers,
        parameterData: resParameter,
        constantData: resConstant,
        tankCount: totTank,
        boilerCount: totBoiler,
      };

      console.log("paload", payload);

      if (Object.keys(val).length > 0) {
        const json = await JSON.stringify(payload);
        // console.log('json', json)
        const blob = new Blob([json], { type: "application/json" });
        const url = URL.createObjectURL(blob);
        const fileName = val.vesselName + "_" + val.uniqueNo + ".json";
        const a = document.createElement("a");
        a.href = url;
        a.download = fileName;
        a.click();
        URL.revokeObjectURL(url);
      } else {
        return null;
      }
    }
  };

  return (
    <React.Fragment>
      <ToastContainer />
      {/* Add Item Modal*/}
      <Modal show={visible} onHide={() => setVisible(false)} size="lg">
        <Modal.Header className="bg-primary-blue" closeButton>
          <Modal.Title>Add Vessel</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form className="row g-3">
            <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{"Vessel Name"}</label>
                  <input
                    className={
                      errors.vesselname
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    placeholder="Enter vessel name"
                    name="vesselname"
                    {...register("vesselname")}
                  />

                  <span className="text-danger">
                    {errors.vesselname?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{"Unique Number"}</label>
                  <input
                    className={
                      errors.uniqueno
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    placeholder="Enter Unique No"
                    name="uniqueno"
                    {...register("uniqueno")}
                  />

                  <span className="text-danger">
                    {errors.uniqueno?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{"IMO Number"}</label>
                  <input
                    className={
                      errors.imonumber
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    placeholder="Enter IMO No"
                    name="imonumber"
                    {...register("imonumber")}
                  />

                  <span className="text-danger">
                    {errors.imonumber?.message}
                  </span>
                </div>
              </div>

              <div className="col-12 col-md-6">
                <div className="form-group mb-2">
                  <label className="poppins-bold">{"GT"}</label>
                  <input
                    className={
                      errors.gt
                        ? "form-control is-invalid f-16"
                        : " f-16 form-control"
                    }
                    type="text"
                    placeholder="Enter gt"
                    name="gt"
                    {...register("gt")}
                  />

                  <span className="text-danger">{errors.gt?.message}</span>
                </div>
              </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button className="btn btn-secondary" onClick={() => setVisible(false)}>
            Close
          </button>
          <button
            className="btn btn-primary-blue"
            onClick={handleSubmit(onSubmit)}
            disabled={loading}
          >
            {loading && <Spinner animation='border' variant="dark" size="sm" />}

            {" Save"}
          </button>
        </Modal.Footer>
      </Modal>

      <div className="py-4">
        <div className="container-fluid">
          <div className="row">
            <div className="col-12">
              <div className="card">
                <div className="card_title bg-primary-blue">
                  <h5 className="m-0">
                    {/* <ArrowLeftCircle className="me-2 cursor-pointer" onClick={() => navigate(-1)} /> */}
                    {" Vessel"}
                  </h5>
                </div>

                <div className="card-body">
                  {/* <CreateVessalMaster /> */}
                  <div className="d-flex justify-content-between col-md-12">
                    <input
                      style={{ maxWidth: "300px" }}
                      className="form-control"
                      type="text"
                      name="search"
                      placeholder="Search"
                      onChange={(e) => {
                        fetchVessels("search", e.target.value);
                      }}
                    />
                    <button
                      className="btn bg-primary-blue "
                      type="submit"
                      onClick={() => setVisible(!visible)}
                    >
                      {"Add Vessel"}
                    </button>
                  </div>

                  <div
                    className="table-responsive"
                    style={{ minHeight: "40vh" }}
                  >
                    <table
                      className="table table-bordered mt-4 table-striped table-sm"
                      width={"100%"}
                    >
                      <thead className="thead-light">
                        <tr className="f-16 text-center">
                          <th scope="col">{"Sr"}</th>
                          <th scope="col">{"Vessel"}</th>
                          <th scope="col">{"IMO No."}</th>
                          <th scope="col">{"Unique No."}</th>
                          <th scope="col">{"GT"}</th>
                          <th>{"Action"}</th>
                          <th>{"Other Action"}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {state.loading ? (
                            <Spinner color="primary" size="lg" />
                        ) : (
                          state.vesselList.map((val, i) => (
                            <tr key={i} className=" text-center f-16">
                              <td>{i + 1 + srNo}</td>
                              <td>{val.vesselName}</td>
                              <td>{val.imoNumber}</td>
                              <td>{val.uniqueNo}</td>
                              <td>{val.gt}</td>
                              <td>
                                <EditVessel
                                  fetchVessels={fetchVessels}
                                  data={val}
                                />
                                <Trash2
                                  height="18"
                                  width="18"
                                  className="cursor-pointer me-1"
                                  onClick={() => delVessel(val)}
                                />
                                <Download
                                  height="18"
                                  width="18"
                                  className="cursor-pointer me-1"
                                  onClick={() => downloadJson(val)}
                                />
                              </td>
                              <td>
                                <Dropdown>
                                  <Dropdown.Toggle
                                    variant='secondary' 
                                    size='sm'
                                    className="fw-bold"
                                  >
                                    More
                                  </Dropdown.Toggle>
                                  <Dropdown.Menu>
                                    <Dropdown.Item
                                      component="button"
                                      className="cursor-pointer"
                                      onClick={() => {
                                        navigate("/optiheat/tankMaster", {
                                          state: val,
                                        });
                                      }}
                                    >
                                      {" Tanks"}
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                      component="button"
                                      onClick={() => {
                                        navigate("/optiheat/boilerMaster", {
                                          state: val,
                                        });
                                      }}
                                    >
                                      {"Boilers"}
                                    </Dropdown.Item>
                                  </Dropdown.Menu>
                                </Dropdown>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>

                  {/* ) : apiBySearch ? (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {'No Vessels Found !'}
                    </p>
                  ) : (
                    <p className="text-danger mb-0 f-22 text-center mt-4">
                      {'Vessel list is empty'}
                    </p>
                  )} */}
                  <div className="card-body p-0">
                    <div className="mt-4">
                      <Pagination
                        className="justify-content-end mb-0"
                        aria-label="Page navigation example"
                      >
                        <Pagination.Item
                          disabled={state.offset === 1 ? true : false}
                          className="cursor_pointer"
                          onClick={() => {
                            dispatch({
                              type: "HANDLE_ACTION",
                              offset: state.offset - 1,
                              loading: state.loading,
                              error: state.error,
                              vesselList: state.vesselList,
                              limit: state.limit,
                              maxPage: state.maxPage,
                            });
                            setSrNo((prevC) => prevC - 10);
                          }}
                        >
                          {"Previous"}
                        </Pagination.Item>
                        <Pagination.Item active>{state.offset}</Pagination.Item>
                        <Pagination.Item
                          disabled={
                            state.offset === state.maxPage ||
                            state.maxPage === 0
                              ? true
                              : false
                          }
                          className="cursor_pointer"
                          onClick={() => {
                            dispatch({
                              type: "HANDLE_ACTION",
                              offset: state.offset + 1,
                              loading: state.loading,
                              error: state.error,
                              vesselList: state.vesselList,
                              limit: state.limit,
                              maxPage: state.maxPage,
                            });
                            setSrNo((prevC) => prevC + 10);
                          }}
                        >
                          {"Next"}
                        </Pagination.Item>
                      </Pagination>

                      <p className="f-14 text-muted float-start mt-1">{`Page: ${state.offset} out of ${state.maxPage}`}</p>
                      <p className="f-14 text-muted float-end mt-1">{`Total Records: ${totalRecords}`}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default VesselMaster;
