import axios from 'axios'
import client from '../constants/AxiosInstance'
import {
  saveVessel,
  getVessels,
  updateVessel,
  saveTank,
  getTanks,
  updateTank,
  saveBoiler,
  getBoilers,
  updateBoiler,
  removeTank,
  removeBoiler,
  removeVessel,
  saveUser,
  getUsers,
  updateUser,
  removeUser,
  saveVesseladmin,
  updateVesseladmin,
  deleteVesseladmin,
  getVesseladmin,
  resetPassword,
  saveBoilerSpec,
  saveParameter,
  getParameters,
  updateParameter,
  removeParameter,
  saveParameterConstant,
  getParameterConstants,
  updateParameterConstant,
  removeParameterConstant,
} from '../constants'

// Vessel Master
export const save_vessel = (payload) => {
  return client.post(saveVessel, payload)
}

export const get_vessels = (payload) => {
  return client.get(getVessels, { params: payload })
}

export const update_vessel = (payload, id) => {
  return client.put(updateVessel, payload, { params: { id: id } })
}

export const delete_vessel = (payload) => {
  return client.delete(removeVessel, { params: payload })
}

// Tank Master
export const save_tank = (payload) => {
  return client.post(saveTank, payload)
}

export const get_tanks = (params) => {
  return client.get(getTanks, { params: params })
}

export const update_tank = (payload, params) => {
  return client.put(updateTank, payload, { params: params })
}

export const delete_tank = (params) => {
  return client.delete(removeTank, { params: params })
}

// Boiler Master
export const save_boiler = (payload) => {
  return client.post(saveBoiler, payload)
}

export const get_boilers = (params) => {
  return client.get(getBoilers, { params: params })
}

export const update_boiler = (payload, params) => {
  return client.put(updateBoiler, payload, { params: params })
}

export const delete_boiler = (params) => {
  return client.delete(removeBoiler, { params: params })
}

// Boiler Specifications
export const save_boiler_spec = (payload) => {
  return client.post(saveBoilerSpec, payload)
}

// User Master
export const save_user = (payload) => {
  return client.post(saveUser, payload)
}

export const get_users = (payload) => {
  return client.get(getUsers, { params: payload })
}

export const update_user = (payload, id) => {
  return client.put(updateUser, payload, { params: id })
}

export const delete_user = (payload) => {
  return client.delete(removeUser, { params: payload })
}

//vessel-admin-master

export const save_vessel_admin = (payload) => {
  return client.post(saveVesseladmin, payload)
}

export const get_vessel_admin = (params) => {
  return client.get(getVesseladmin, { params: params })
}

export const update_vessel_admin = (payload, params) => {
  return client.put(updateVesseladmin, payload, { params: params })
}

export const delete_vessel_admin = (params) => {
  return client.delete(deleteVesseladmin, { params: params })
}
export const passwordResetApi = (payload) => {
  return client.put(resetPassword, payload)
}

// Parameter Master
export const save_parameter = (payload) => {
  return client.post(saveParameter, payload)
}

export const get_parameters = (payload) => {
  return client.get(getParameters, { params: payload })
}

export const update_parameter = (payload, params) => {
  return client.put(updateParameter, payload, { params: params })
}

export const delete_parameter = (payload) => {
  return client.delete(removeParameter, { params: payload })
}


// Parameter Constant Master
export const save_ParameteConstant = (payload) => {
  return client.post(saveParameterConstant, payload)
}

export const get_ParameterConstants = (payload) => {
  return client.get(getParameterConstants, { params: payload })
}

export const update_ParameteConstant = (payload, params) => {
  return client.put(updateParameterConstant, payload, { params: params })
}

export const delete_ParameteConstant = (payload) => {
  return client.delete(removeParameterConstant, { params: payload })
}
