import axios from 'axios'
import { RefreshToken } from './index'

const client = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
})

function saveToken(access_token) {
  localStorage.setItem('access', access_token)
  // localStorage.setItem('refresh', refresh_token);
}

function destroyToken() {
  localStorage.removeItem('access')
  localStorage.removeItem('refresh')
}

function refresh() {
  return new Promise((resolve, reject) => {
    client
      .post(RefreshToken, {
        refresh: localStorage.getItem('refresh'),
      })
      .then((response) => {
        saveToken(response.data.access, response.data.refresh_token)
        return resolve(response.data.access_token)
      })
      .catch((error) => {
        destroyToken()
        // window.location.replace(`${process.env.PUBLIC_URL}/pcms/signin`)
        return reject(error)
      })
  })
}

client.interceptors.request.use((config) => {
  var authToken = localStorage.getItem('access')
  console.log('authToken', authToken)
  config.headers = { Authorization: `Bearer ${authToken}` }
  console.log(config)
  return config
})

client.interceptors.response.use(
  (res) => res,
  (error) => {
    const status = error.response ? error.response.status : null

    switch (status) {
      case 401:
        localStorage.removeItem('access')
        // localStorage.removeItem('refresh');
        // window.location.replace(`${process.env.PUBLIC_URL}/pcms/signin`)
        break

      case 403:
        localStorage.removeItem('access')
        // window.location.replace(`${process.env.PUBLIC_URL}/pcms/signin`)
        break
      //localStorage.removeItem('refresh');

      case 404:
        localStorage.removeItem('access')
        // window.location.replace(`${process.env.PUBLIC_URL}/pcms/signin`)
        break

        default:
          break
    }

    if (status === 401 || status === 403 || status === 404) {
      //window.location.replace('/login');
      localStorage.removeItem('access')
      window.location.replace(`${process.env.PUBLIC_URL}/applogin`)
      //localStorage.removeItem('refresh');
    }

    // status might be undefined
    if (!status) {
      refresh()
    }
    return Promise.reject(error)
  },
)

export default client
